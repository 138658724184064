import {
  Box,
  BoxProps, Checkbox, FormControl,
  Grid, InputLabel,
  List, ListItem,
  MenuItem, Select,
  Typography
} from "@mui/material";
import React, { useState} from "react";


export interface JobPreset {
  id: string;
  name: string;
  category: string;
  description: string;
  job_template_id: string;
  params: any;
}

interface JobParams {
  schema?: string;
  data: any;
  preset?: string;
  selectedOutputs: number[];
}
export interface JobPresetFormProps extends Omit<BoxProps, 'onSubmit'|'onChange'> {
  jobParams?: JobParams;
  presets: JobPreset[];
  onChange: (value: JobParams) => void;
}

export const JobPresetUnpackaged = 'Custom'

const JobPresetForm = ({jobParams, presets, onChange}: JobPresetFormProps) => {
  const categories = Array.from(new Set([JobPresetUnpackaged, ...presets.map(p => p.category)]))
  const selectedOutputs = (jobParams?.selectedOutputs)||[]

  const selectedPreset = jobParams ? presets.find(p => p.id === jobParams.preset) : null
  const [selectedCategory, setSelectedCategory] = useState(selectedPreset?.category || categories[0])


  const filteredPresets = presets.filter(p => p.category === selectedCategory)
  const handleCategoryChange = (val: string) => {
    setSelectedCategory(val);
    if (val !== selectedCategory && selectedPreset) {
      const updatedJobParams: JobParams = { schema: jobParams?.schema, preset: '', selectedOutputs: [], data: null }
      onChange(updatedJobParams)
    }
  }
  const handlePresetChange = (val: string) => {
    const newPreset = presets.find(p => p.id === val)
    const selectedOutputs = (newPreset?.params.outputs||[]).map((_:any, i:number) => i)
    const updatedJobParams: JobParams = {schema: jobParams?.schema||'', preset: val, data: newPreset?.params||{}, selectedOutputs: selectedOutputs}
    onChange(updatedJobParams)
  };
  const handleOutputSelect = (e: any, i: number) => {
    const updatedOutputs = [...selectedOutputs.filter(v => v !== i)]
    if ((jobParams?.selectedOutputs||[]).indexOf(i) === -1) {
      updatedOutputs.push(i)
    }
    const updatedData = {outputs: [], ...selectedPreset?.params}
    updatedData.outputs = updatedData.outputs.filter((_:any, i:number) => updatedOutputs.indexOf(i) !== -1)
    const updatedJobParams: JobParams = {...jobParams, selectedOutputs: updatedOutputs, data: updatedData}
    onChange(updatedJobParams)
  };


  return <Grid container spacing={2} mt={4}>
    <Grid item xs={4}>
      <Box>
        <Typography variant="h3" color="secondary" className="color-blue1">Category</Typography>
        <FormControl sx={{mt: 2}} fullWidth color="secondary" required>
          <InputLabel id={"preset-label"}>Select an option</InputLabel>
          <Select
            labelId={"preset-label"}
            label="Select an option"
            value={selectedCategory || JobPresetUnpackaged}
            onChange={(e) => handleCategoryChange(e.target.value)}
          >
            {categories.map(
              (val) =>
                <MenuItem key={val.toLowerCase()} value={val}>{val}</MenuItem>
            )}
          </Select>
        </FormControl>
      </Box>
    </Grid>
    <Grid item xs={8}>
      <Box>
        <Typography variant="h3" color="secondary" className={filteredPresets.length > 0 ? "color-blue1" : "color-grey1"}>Preset</Typography>
        <FormControl sx={{mt: 2}} fullWidth color="secondary" disabled={!selectedCategory || filteredPresets.length === 0}>
          <InputLabel id={"template-label"}>Select an option</InputLabel>
          <Select
            labelId={"template-label"}
            value={selectedPreset?.id}
            label="Select an option"
            onChange={(e) => handlePresetChange(e.target.value)}
            renderValue={() => <Typography variant="body1">{selectedPreset?.name}</Typography>}
          >
            {filteredPresets.map(
              (val) =>
                <MenuItem key={val.id} value={val.id}>{val.name}</MenuItem>
            )}
          </Select>
        </FormControl>
        {selectedPreset &&
            <Box display="flex" flexDirection="column">
                <List dense>
                  {(selectedPreset.params?.outputs||[]).map((o: any, i: number) => <ListItem key={`output-${i}`}><Checkbox size="small" onChange={(e) => handleOutputSelect(e, i)} checked={selectedOutputs.indexOf(i) !== -1}/> <Typography variant="caption">{o.name}</Typography></ListItem>)}
                </List>
            </Box>
        }
      </Box>
    </Grid>
  </Grid>
}

/* JobDataForm.defaultProps = {
  onChange: null,
  formData: {
    source: {dataSourceDescriptor: ''},
    destination: {dataSourceDescriptor: 'bucket'}
  }
}*/


export default JobPresetForm
