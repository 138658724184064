import * as React from 'react';
import { Typography } from '@mui/material';
import Paper, {PaperProps} from '@mui/material/Paper';
import "../../styles/sass/containers.scss"
import {ReactNode} from "react";

export interface DisplayDataPaperProps extends PaperProps {
    title: string;
    children: ReactNode;
    headerActions?: ReactNode;
    style?: React.CSSProperties;
}

const DisplayDataPaper = ({title, headerActions, children, style}: DisplayDataPaperProps) => {

    return (
        <Paper elevation={3} style={style}>
            <div className={"container-vertical content-start"}>
                <div className={"container-header-space-between"}>
                    <Typography variant={"h3"} className={"color-blue1"}>
                        {title}
                    </Typography>
                    {headerActions && <div>{headerActions}</div>}
                </div>
                <div className={"container-horizontal content-center"}>
                    {children}
                </div>
            </div>
        </Paper>
    );
}

export default DisplayDataPaper
