import { Button, CircularProgress, Divider, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import * as React from "react";
import { useState } from "react";
import { ReactComponent as ChipBlue } from "../assets/walletIcons/chip-blue-large.svg";
import { useModals } from "../hoc/Modals/Provider";
import { PricingPlan } from "../types/utils";
import DisplayDataPaper from "./displayDataComponents/_displayDataPaper";
import ByteChipsCard from "./displayDataComponents/wallet/ByteChipsCard";
import TopUpForm, { TopUpParams } from "./formComponents/TopUpForm";
import ByteChip from "./icons/ByteChip";


export interface WalletCardProps {
  stockBalance: number,
  subscriptionPlan?: number,
  lastTopUp?: number,
  subscriptionStatus?: string
  onChangePlanType?: (value: number) => void,
  onTopUpRequest: (currency: string, amount: number) => Promise<TopUpParams>,
  onCancelTopUpRequest: (topUpRequestId: string, cancelMessage: string) => Promise<void>,
  onSubscriptionClick: () => Promise<void>
  stockPlans: PricingPlan[]
  subscriptionPlans: PricingPlan[]
  pendingTopUpId: string
}

const WalletCard = ({ ...props }: WalletCardProps) => {
  const modals = useModals()
  const {
    stockBalance, stockPlans, subscriptionPlans, subscriptionStatus, onTopUpRequest, onCancelTopUpRequest, onSubscriptionClick, pendingTopUpId, onChangePlanType,
    lastTopUp = 0
  } = props;

  const [topUpOpen, setTopUpOpen] = useState(false)

  const handleTopUpClose = () => {
    if (!pendingTopUpId) {
      onCancelTopUpRequest(pendingTopUpId, "close dialog").then(() => {
        setTopUpOpen(false)
      })
    } else {
      setTopUpOpen(false)
    }
  }
  const handleTopUpOpen = async () => {
    if (!modals) {
      return;
    }
    //setTopUpOpen(true)
    try {
      const selectAmountResult = await modals.Custom<{
        value?: number
      }>('Top-Up', (closeModal: (ok: boolean, data: {
        value?: number
      }) => void) =>
        <TopUpForm plans={stockPlans} subscriptionPlans={subscriptionPlans} onTopUpRequest={onTopUpRequest} onChangePlanType={(value) => {
          closeModal(false, {})
          onChangePlanType?.(value)
        }} />,
        {
          iconHeader: true,
          size: 'xs'
        }
      )
      if (!selectAmountResult.ok || !selectAmountResult.data?.value) {
        return
      }
    } catch (e) {
      console.error('Top-up flow canceled', e)
    }
  }

  const styleCircularProgress = {
    left: 0,
    bottom: 0,
    right: 0,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }


  return (
    <DisplayDataPaper
      title={"Wallet"}
      headerActions={<></>}
    >
      <Box flexGrow={2} mx={2} my={1}>
        <Box height={260}>
          <Box sx={styleCircularProgress}>
            <CircularProgress
              color={"secondary"}
              variant={"determinate"}
              value={0}
              thickness={3.5}
              size={"15em"}
              style={{ "zIndex": 11 }}
            />
          </Box>
          <Box sx={{ ...styleCircularProgress, top: "-14.7em" }}>
            <CircularProgress
              color="grey"
              variant={"determinate"}
              value={100}
              thickness={1.8}
              size={"14.2em"}
              style={{ "zIndex": 10 }}
            />
          </Box>

          <Box sx={{ ...styleCircularProgress, top: "-26.2em" }}>
            <CircularProgress
              color={"warning"}
              variant={"determinate"}
              value={(stockBalance / lastTopUp) * 100} //TODO: put how many bytechips are blocked by the current running jobs
              thickness={5.2}
              size={"9em"}
              style={{ "zIndex": 11 }}
            />
          </Box>
          <Box sx={{ ...styleCircularProgress, top: "-34.9em" }}>
            <CircularProgress
              color="grey"
              variant={"determinate"}
              value={100}
              thickness={2.9}
              size={"8.2em"}
              style={{ "zIndex": 10 }}
            />
          </Box>
          <Box sx={{ ...styleCircularProgress, top: "-41em" }}>
            <Paper className={"subvariant-round"} elevation={3}>
              <Box mt={2.1} mb={1.2} ml={2.6} mr={2}>
                <ChipBlue></ChipBlue>
              </Box>
            </Paper>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="center" mb={2}>
          <Box mx={1}><ByteChipsCard title="Stock" amount={stockBalance} color="yellow" /></Box>
        </Box>
        <Divider className={"divider-blue-5"}></Divider>


        <Box my={2}>
          <Button variant={"outlined"}
            style={{ width: "100%" }}
            onClick={handleTopUpOpen}
            startIcon={<ByteChip color="primary" size="small" />}
            color={"primary"}>
            <Typography variant={"button1"}>Top-up</Typography></Button>
        </Box>
        {/*<Dialog onClose={handleTopUpClose} open={topUpOpen} PaperProps={{className: "modal-top-up"}}>
          <div className={"icon-top-up"}>
            <IconTopUp/>
          </div>
          <DialogContent dividers>
            <div className={"container-horizontal  content-end"}>
              <Button onClick={handleTopUpClose}><Xmark></Xmark></Button>
            </div>
            <TopUpForm onTopUpRequest={onTopUpRequest} balance={stockBalance} getExchangeRate={getExchangeRate}/>
          </DialogContent>
        </Dialog>*/}
      </Box>
    </DisplayDataPaper>
  );
}

export default WalletCard
