import React, { useEffect, useState } from 'react';
import './_signin-page.scss'
import '@bytenite/components/src/styles/sass/containers.scss'

//social media icons
import { ReactComponent as LogoLogin } from "@bytenite/components/src/assets/bytenite-logo-payoff-white.svg";
import { ReactComponent as ArrowRightPurple } from "@bytenite/components/src/assets/arrow-right-purple.svg";
import { ReactComponent as ArrowRightGray } from "@bytenite/components/src/assets/arrow-right-gray.svg";
import { ReactComponent as ArrowLeftWhite } from "@bytenite/components/src/assets/jobIcons/arrow-left-white.svg";

import { useLocation, useNavigate } from "react-router-dom";
import { Alert, AlertTitle, Autocomplete, Box, Button, Checkbox, CircularProgress, FormHelperText, Grid, Input, InputAdornment, InputLabel, Stack, SvgIcon, Tab, Tabs, TextField, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import OtpInput from "@bytenite/components/src/components/formComponents/otpInput/OtpInput";
import Layout from "../../components/Layout";
import { AuthTokenRequest } from "../../client";
import { useIdpContext } from '@bytenite/auth/src/hoc/Idp/context';
import { useAuthContext } from "../../hoc/Auth/context";
import { useEffectOnce } from "../../hooks/UseEffectOnce";
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';



import { BiBuildings } from "react-icons/bi";
import { FaRegUser } from "react-icons/fa6";
import { FiLock } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";
import { CiGlobe } from "react-icons/ci";
import { z } from 'zod';

import axios from 'axios';



type AuthMode = 'signin' | 'signup';
const AUTH_MODE_STORAGE_KEY = "bytenite_auth_mode"

interface SigninPageProps {
  onComplete?: ({ nonce, email }: { nonce: string; email: string }) => void;
  isSignUp?: boolean
}

interface LoginSignupFormProps {
  form: LoginForm;
  defaultAuthMode?: AuthMode;
  onAuthModeChange?: (authMode: AuthMode) => void;
  setForm: (form: LoginForm) => void;
  setNextDisabled: (disabled: boolean) => void
}

interface LoginForm {
  email: string;
  company?: string;
  country?: string
  firstName?: string;
  lastName?: string;
  isNewUser?: boolean;
  accessCode?: string
}

{/* Sign up form fields */ }

const SignUpForm: React.FC<{ form: LoginForm; setForm: (form: LoginForm) => void; setNextDisabled: (disabled: boolean) => void }> = ({
  form,
  setForm,
  setNextDisabled,
}) => {

  const [shrinkAccessCode, setShrinkAccessCode] = useState(!!form.accessCode);
  const [shrinkFirstName, setShrinkFirstName] = useState(!!form.firstName);
  const [shrinkLastName, setShrinkLastName] = useState(!!form.lastName);
  const [shrinkEmail, setShrinkEmail] = useState(!!form.email);
  const [shrinkCompany, setShrinkCompany] = useState(!!form.company);
  const [shrinkCountry, setShrinkCountry] = useState(!!form.country);

  const [countries, setCountries] = useState<{ label: string, id: string }[]>([]);
  const [country, setCountry] = useState('');
  const [error, setError] = useState<string | null>(null);

  const handleCountryChange = (event: any, newValue: string | null) => {
    //@ts-ignore
    setCountry(newValue.label || '');
    //@ts-ignore
    setForm({ ...form, country: newValue.label || '' });
    validateForm();
  };

  function validateEmail(email: string) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const validateForm = () => {
    const allFieldsFilled = [
      form.firstName?.trim(),
      form.lastName?.trim(),
      form.email?.trim(),
      form.company?.trim(),
      form.country?.trim(),
      form.accessCode?.trim(),
    ].every(Boolean);

    const isEmailValid = validateEmail(form.email || '');
    setNextDisabled(!(allFieldsFilled && isEmailValid));
  };

  // const styleProps = {
  //   className: 'subvariant-white',
  //   inputProps: {
  //     className: 'subvariant-white',
  //   }
  // }

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get('https://restcountries.com/v3.1/all');
        const countryList = response.data.map((country: any) => ({
          label: country.name.common,
          id: country.cca2,
        }));

        setCountries(countryList);
      } catch (error) {
        console.error('Error fetching country data:', error);
      }
    };

    fetchCountries();
  }, []);


  return <Grid spacing={2} container>

    <Grid item xs={6}>
      <FormControl fullWidth>
        <TextField
          color="primary"
          autoComplete="off"
          className="subvariant-white"
          onFocus={() => setShrinkFirstName(true)}
          onBlur={(e) => setShrinkFirstName(!!e.target.value)}
          InputLabelProps={{
            sx: {
              ml: 4.5,
              color: 'white',
              opacity: 0.5,
              '&.MuiInputLabel-shrink': {
                color: 'white',
                opacity: 1
              }
            },
            shrink: shrinkFirstName

          }}
          sx={(theme) => ({
            "& .Mui-focused .MuiInputAdornment-root": {
              color: 'white'
            },
            "& .MuiOutlinedInput-notchedOutline": {
              px: 5.5,
            }
          })}
          name="firstName"
          label="First Name*"
          InputProps={{
            className: 'subvariant-white',
            startAdornment: (
              <InputAdornment position="start">
                <FaRegUser color='white' />
              </InputAdornment>
            )
          }}
          value={form.firstName || ''}
          onChange={(e) => {
            setForm({ ...form, firstName: e.target.value });
            validateForm();
          }}
          variant="outlined"
          fullWidth
        />
      </FormControl>
    </Grid>

    <Grid item xs={6}>
      <FormControl fullWidth>
        <TextField
          color="primary"
          autoComplete="off"
          className="subvariant-white"
          onFocus={() => setShrinkLastName(true)}
          onBlur={(e) => setShrinkLastName(!!e.target.value)}
          InputLabelProps={{
            sx: {
              ml: 4.5,
              color: 'white',
              opacity: 0.5,
              '&.MuiInputLabel-shrink': {
                color: 'white',
                opacity: 1
              }
            },
            shrink: shrinkLastName

          }}
          sx={(theme) => ({
            "& .Mui-focused .MuiInputAdornment-root": {
              color: 'white'
            },
            "& .MuiOutlinedInput-notchedOutline": {
              px: 5.5,
            }
          })}
          name="lastName"
          label="Last Name*"
          InputProps={{
            className: 'subvariant-white',
            startAdornment: (
              <InputAdornment position="start">
                <FaRegUser color='white' />
              </InputAdornment>
            )
          }}
          value={form.lastName || ''}
          onChange={(e) => {
            setForm({ ...form, lastName: e.target.value });
            validateForm()
          }}
          variant="outlined"
          fullWidth
        />
      </FormControl>
    </Grid>
    <Grid item xs={12}>
      <FormControl fullWidth>
        <TextField
          color="primary"
          autoComplete="off"
          className="subvariant-white"
          onFocus={() => setShrinkEmail(true)}
          onBlur={(e) => setShrinkEmail(!!e.target.value)}
          InputLabelProps={{
            sx: {
              ml: 4.5,
              color: 'white',
              opacity: 0.5,
              '&.MuiInputLabel-shrink': {
                color: 'white',
                opacity: 1
              }
            },
            shrink: shrinkEmail

          }}
          sx={(theme) => ({
            "& .Mui-focused .MuiInputAdornment-root": {
              color: 'white'
            },
            "& .MuiOutlinedInput-notchedOutline": {
              px: 5.5,
            }
          })}
          name="email"
          label="Email*"
          InputProps={{
            className: 'subvariant-white',
            startAdornment: (
              <InputAdornment position="start">
                <AiOutlineMail color='white' />

                {/* <MailOutlineIcon sx={{ color: 'white' }} /> */}
              </InputAdornment>
            )
          }}
          value={form.email || ''}
          onChange={(e) => {
            setForm({ ...form, email: e.target.value });
            validateForm()
          }}
          variant="outlined"
          fullWidth
          error={Boolean(error)}
          helperText={error}
          FormHelperTextProps={{
            sx: {
              color: 'white',
              fontWeight: 'semibold',
              fontSize: '14px',
              backgroundColor: '#B0000040',
              "&.Mui-error": {
                color: "white",
                marginLeft: '0px',
                width: '100%',
                textAlign: 'center'
              }
            }
          }}
        />
      </FormControl>
    </Grid>
    <Grid item xs={12}>
      <FormControl fullWidth>
        <TextField
          color="primary"
          autoComplete="off"
          className="subvariant-white"
          onFocus={() => setShrinkCompany(true)}
          onBlur={(e) => setShrinkCompany(!!e.target.value)}
          InputLabelProps={{
            sx: {
              ml: 4.5,
              color: 'white',
              opacity: 0.5,
              '&.MuiInputLabel-shrink': {
                color: 'white',
                opacity: 1
              }
            },
            shrink: shrinkCompany

          }}
          sx={(theme) => ({
            "& .Mui-focused .MuiInputAdornment-root": {
              color: 'white'
            },
            "& .MuiOutlinedInput-notchedOutline": {
              px: 5.5,
            }
          })}
          name="company"
          label="Company*"
          InputProps={{
            className: 'subvariant-white',
            startAdornment: (
              <InputAdornment position="start">
                <BiBuildings color='white' />
              </InputAdornment>
            )
          }}
          value={form.company || ''}
          onChange={(e) => {
            setForm({ ...form, company: e.target.value });
            validateForm()
          }}
          variant="outlined"
          fullWidth
        />
      </FormControl>
    </Grid>

    <Grid item xs={12}>
      <FormControl fullWidth>
        <Autocomplete
          disablePortal
          //@ts-ignore
          options={countries}
          onOpen={() => setShrinkCountry(true)}
          onClose={() => setShrinkCountry(false)}
          value={country}
          onChange={handleCountryChange}
          renderInput={(params) => <TextField {...params}
            label="Country*"
            InputLabelProps={{
              shrink: shrinkCountry || !!country,
              style: {
                marginLeft: '36px',
                color: 'white',
                opacity: shrinkCountry ? 1 : 0.5,
              },
            }}
            sx={(theme) => ({
              "& .Mui-focused .MuiInputAdornment-root": {
                color: 'white'
              },
              "& .MuiOutlinedInput-notchedOutline": {
                px: 5.5,
              },
              '&.MuiInputLabel-shrink': {
                color: 'white',
                px: 5.5,
              },
            })}

            InputProps={{
              ...params.InputProps,
              className: 'subvariant-white',
              startAdornment: (
                <InputAdornment position="start">
                  <CiGlobe color="white" />
                </InputAdornment>
              )
            }}

          />}
        />
      </FormControl>
    </Grid>

    <Grid item xs={12} >
      <FormControl fullWidth>
        <TextField
          color="primary"
          autoComplete="off"
          className="subvariant-white"
          onFocus={() => setShrinkAccessCode(true)}
          onBlur={(e) => setShrinkAccessCode(!!e.target.value)}
          InputLabelProps={{
            sx: {
              ml: 4.5,
              color: 'white',
              opacity: 0.5,
              '&.MuiInputLabel-shrink': {
                color: 'white',
                opacity: 1
              }
            },
            shrink: shrinkAccessCode

          }}
          sx={(theme) => ({
            "& .Mui-focused .MuiInputAdornment-root": {
              color: 'white'
            },
            "& .MuiOutlinedInput-notchedOutline": {
              px: 5.5,
            }
          })}
          name="accessCode"
          label="Access Code*"
          InputProps={{
            className: 'subvariant-white',
            startAdornment: (
              <InputAdornment position="start">
                <FiLock color='white' />
              </InputAdornment>
            )
          }}
          value={form.accessCode}
          onChange={(e) => {
            setForm({ ...form, accessCode: e.target.value });
            validateForm()
          }}
          variant="outlined"
          fullWidth
        />
      </FormControl>
    </Grid>

    <Grid item xs={12}>
      <Typography variant="caption" color="white">By continuing, you agree to ByteNite’s <a href="https://www.bytenite.com/legal/terms-of-use" target="_blank" style={{ color: 'white' }} >Terms of Service</a> and <a href="https://www.bytenite.com/legal/privacy-policy" target="_blank" style={{ color: 'white' }}>Privacy Policy</a></Typography>
    </Grid>
  </Grid>
};
const SignInForm: React.FC<{ form: LoginForm; setForm: (form: LoginForm) => void; setNextDisabled: (disabled: boolean) => void }> = ({
  form,
  setForm,
  setNextDisabled,
}) => {
  const [shrink, setShrink] = useState(!!form.email);
  const [error, setError] = useState<string | null>(null);

  // const styleProps = {
  //   className: 'subvariant-white',
  //   inputProps: {
  //     className: 'subvariant-white',
  //   }
  // }nirajan_m@sunai.xyz

  const loginSchema = z.object({
    email: z.string().email('Invalid email format').min(1, 'Email is required'),
  });

  useEffect(() => {
    if (form.email === "") {
      setError(null);
    }
    setShrink(!!form.email);
  }, [form.email]);


  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setForm({ ...form, email: value });

    // Validate the input using Zod
    try {
      loginSchema.parse({ email: value });
      setError(null);
      setNextDisabled(false);
    } catch (err) {
      if (err instanceof z.ZodError) {
        setError(err.errors[0].message);
      }
      setNextDisabled(true);
    }
  };

  return (
    <Grid spacing={2} container>
      <Grid item xs={12} >
        <FormControl fullWidth>
          <TextField
            color="primary"
            autoComplete="off"
            className="subvariant-white"
            onFocus={() => setShrink(true)}
            onBlur={(e) => setShrink(!!e.target.value)}
            InputLabelProps={{
              sx: {
                ml: 4.5,
                color: 'white',
                opacity: 0.5,
                '&.MuiInputLabel-shrink': {
                  color: 'white',
                  opacity: 1
                }
              },
              shrink
            }}
            sx={(theme) => ({
              "& .Mui-focused .MuiInputAdornment-root": {
                color: 'white'
              },
              "& .MuiOutlinedInput-notchedOutline": {
                px: 5.5,
              }
            })}
            name="email"
            label="Email*"
            InputProps={{
              type: 'email',
              className: 'subvariant-white',
              startAdornment: (
                <InputAdornment position="start">
                  <AiOutlineMail color='white' />
                </InputAdornment>
              )
            }}
            value={form.email}
            onChange={handleEmailChange}
            variant="outlined"
            fullWidth
            error={Boolean(error)}
            helperText={error}
            FormHelperTextProps={{
              sx: {
                color: 'white',
                fontWeight: 'semibold',
                fontSize: '14px',
                backgroundColor: '#B0000040',
                "&.Mui-error": {
                  color: "white",
                  marginLeft: '0px',
                  width: '100%',
                  textAlign: 'center'
                }
              }
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
};
const LoginSignupForm: React.FC<LoginSignupFormProps> = ({
  form,
  setForm,
  onAuthModeChange,
  setNextDisabled,
  defaultAuthMode,
}) => {
  const [authMode, setAuthMode] = React.useState(defaultAuthMode === 'signup' ? 1 : 0);


  const validateForm = () => {
    const allFieldsFilled = [
      form.firstName?.trim(),
      form.lastName?.trim(),
      form.email?.trim(),
      form.company?.trim(),
      form.country?.trim(),
      form.accessCode?.trim(),
    ].every(Boolean);

    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.email || '');
    setNextDisabled(!(allFieldsFilled && isEmailValid));
  };


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue !== authMode) {
      setAuthMode(newValue);
      if (onAuthModeChange) {
        setForm({ ...form, email: '' })
        onAuthModeChange(newValue === 0 ? 'signin' : 'signup')
      }
      validateForm();
    }
  };


  // borderBottom: 1, borderColor: 'divider',
  return (
    <Box sx={{ maxWidth: '100vh', width: '386px' }} display="flex" flexDirection="column" alignItems="center">
      <Box sx={{ width: '240px' }}>
        <Tabs value={authMode} onChange={handleChange} textColor="inherit" aria-label="Login or signup" sx={{ justifyContent: 'space-between' }}>
          <Tab label="Log In" />
          <Tab label="Sign Up" />
        </Tabs>
      </Box>
      <Box role="tabpanel" sx={{ width: '100%' }} hidden={authMode !== 0} id="tabpanel-login" pt={4}>
        <SignInForm form={form} setForm={setForm} setNextDisabled={setNextDisabled} />
      </Box>
      <Box role="tabpanel" sx={{ width: '100%' }} hidden={authMode !== 1} id="tabpanel-signup" pt={4}>
        <SignUpForm form={form} setForm={setForm} setNextDisabled={setNextDisabled} />
      </Box>
    </Box>
  );
};

const VerificationStep: React.FC<{ email: string; validOtp: boolean; checkPin: () => void; setOtpValue: (value: string) => void }> = ({
  email,
  validOtp,
  checkPin,
  setOtpValue,
}) => {

  // TODO: ? checkPin={checkPin}
  return (
    <div className="container-vertical content-center">
      <div className="container-horizontal content-center" style={{ textAlign: 'center' }}>
        <Typography color="white" variant="caption">
          {`We've sent a verification code to ${email}, if it's associated with an account.`}
        </Typography>
      </div>

      <div className="container-horizontal content-center">
        {/* <OtpInput color="primary" digits={6} setOtpValue={setOtpValue} isValid={validOtp} setOtpValues={setOtpValues} otpValues={otpValues} /> */}
        <OtpInput color="primary" digits={6} setOtpValue={setOtpValue} isValid={validOtp} />
      </div>
    </div>
  );
};

const TermsOfServiceStep: React.FC<{ acceptTerms: boolean; setAcceptTerms: (value: boolean) => void }> = ({
  acceptTerms,
  setAcceptTerms,
}) => {
  return (
    <Box display="flex" flexDirection="column">
      <div className="container-horizontal content-center">
        <Typography color="white" variant="h3">
          Terms of service
        </Typography>
      </div>
      <Box mt={3}>
        <Checkbox className="subvariant-white" value={acceptTerms} onChange={() => setAcceptTerms(!acceptTerms)} />
        <Typography color="white" variant="caption">
          I accept ByteNite’s
        </Typography>
        <Typography color="white" variant="caption">
          <b>
            <a style={{ color: 'white' }} href="https://app.termly.io/document/terms-of-use-for-saas/78867ce3-ba1d-4196-94e0-fcf8f1776ea1" target="_blank">
              Terms of Use
            </a>
          </b>
        </Typography>
      </Box>
    </Box>
  );
};

const SigninPage: React.FC<SigninPageProps> = ({ isSignUp = false }) => {
  const location = useLocation()
  const authCtx = useAuthContext()
  const queryParams = new URLSearchParams(location.search);

  const loginChallenge = queryParams.get('login_challenge');
  const authModeParam: AuthMode = ['signin', 'signup'].indexOf(queryParams.get('auth_mode')) !== -1 ?
    queryParams.get('auth_mode') as AuthMode : 'signin';
  const storedAuthMode = localStorage.getItem(AUTH_MODE_STORAGE_KEY) as AuthMode

  const [isLoading, setIsLoading] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [step, setStep] = useState(0);
  const [nonce, setNonce] = useState('');
  const [otpValue, setOtpValue] = useState('');
  const [otpValues, setOtpValues] = useState<string[]>(Array(6).fill(''));
  const [validOtp, setValidOtp] = useState(true);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [checkingPin, setCheckingPin] = useState(false);
  const [respToken, setRespToken] = useState('');
  const [authMode, setAuthMode] = useState<AuthMode>(storedAuthMode || authModeParam);
  const [error, setError] = useState<string | undefined>();
  const [snackbarMessage, setSnackbarMessage] = useState<string | undefined>();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [loginForm, setLoginForm] = React.useState<LoginForm>({ email: '', isNewUser: authMode === 'signup', accessCode: '' });

  const idpCtx = useIdpContext();
  const navigate = useNavigate();

  const checkPin = () => {
    setNextDisabled(true);
    setCheckingPin(true);
    const body: AuthTokenRequest = { email: loginForm.email, accessCode: loginForm.accessCode, nonce: nonce, code: otpValue, deviceId: null, loginChallenge: loginChallenge }

    idpCtx.signInWithMail(body).then((resp) => {
      console.log('resp', resp)
      // setCheckingPin(false);
      // setRespToken(resp.token);
      // setStep(step + 1);
    }).catch((err) => {
      setNextDisabled(false);
      setCheckingPin(false);
      err.json().then((r: any) => {
        const responseMessage = r.message;
        const trimmedMessage = responseMessage?.split("]")[1].trim()
        setOpenSnackbar(true);
        setSnackbarMessage(trimmedMessage.charAt(0).toUpperCase() + trimmedMessage.slice(1))
      })
    })
  };

  function validateEmail(email: string) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const requireOtp = (resend: boolean = false) => {
    return new Promise((resolve, reject) => {
      if (authMode === 'signup') {
        if (loginForm.email && validateEmail(loginForm.email) && loginForm.firstName && loginForm.lastName && loginForm.company && loginForm.country && loginForm.accessCode) {
          setNextDisabled(true);
          setCheckingPin(true);
          loginForm.isNewUser = (authMode === 'signup')
          idpCtx
            .requestSignInCode(loginForm)
            .then((resp) => {
              setNonce(resp.nonce);
              if (!resend) {
                setStep(step + 1);
              }
              setNextDisabled(false);
              setCheckingPin(false);
              resolve(resp.nonce)
            })
            .catch((err) => {
              setNextDisabled(false);
              setCheckingPin(false);
              err.json().then((r: any) => {
                const responseMessage = r.message;
                const trimmedMessage = responseMessage?.split("]")[1].trim()
                setOpenSnackbar(true);
                setSnackbarMessage(trimmedMessage.charAt(0).toUpperCase() + trimmedMessage.slice(1))
              });
              reject(err)
            });
        } else {
          setNextDisabled(false);
          setCheckingPin(false);
        }
      } else if (authMode === 'signin')
        if (loginForm.email && validateEmail(loginForm.email)) {
          setNextDisabled(true);
          setCheckingPin(true);
          loginForm.isNewUser = false
          idpCtx
            .requestSignInCode(loginForm)
            .then((resp) => {
              setNonce(resp.nonce);
              if (!resend) {
                setStep(step + 1);
              }
              setNextDisabled(false);
              setCheckingPin(false);
              resolve(resp.nonce)
            })
            .catch((err) => {
              setNextDisabled(false);
              setCheckingPin(false);
              err.json().then((r: any) => {
                const responseMessage = r.message;
                const trimmedMessage = responseMessage?.split("]")[1].trim()
                setOpenSnackbar(true);
                setSnackbarMessage(trimmedMessage.charAt(0).toUpperCase() + trimmedMessage.slice(1))
              });
              reject(err)
            });
        } else {
          // setOpenSnackbar(true);
          // setSnackbarMessage('Email is required')
          reject(new Error("email is required"))
        }
    })
  };

  const handleAuthModeChanged = (newAuthMode: AuthMode) => {
    setAuthMode(newAuthMode)
  }

  const resendEmail = () => {
    setNonce('')
    setIsLoading(true)
    requireOtp(true).finally(() => setIsLoading(false))
  }

  const nextStep = () => {
    switch (step) {
      case 0:
        requireOtp();
        setValidOtp(true);
        break;
      case 1:
        checkPin();
        break;
    }
  };

  useEffectOnce(() => {
    if (!loginChallenge) {
      authCtx.getLoginUrl().then((url) => {
        localStorage.setItem(AUTH_MODE_STORAGE_KEY, authModeParam)
        window.location.href = url
      }).catch((err) => {
        setError(err.message)
      })
    }
    if (step === 0 && loginChallenge) {
      localStorage.removeItem(AUTH_MODE_STORAGE_KEY)
      idpCtx.skipLogin(loginChallenge).then(resp => {
        if (resp.skip) {
          window.location.href = resp.redirectTo
        } else {
          setIsLoading(false)
        }
      }).catch((err) => {
        setError(err.toString())
        setIsLoading(false)
      })
    }
  }) //, [loginChallenge]

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const loadingComponent: React.JSX.Element = error ?
    <Box display="flex" flexDirection="column" alignItems="center"> <Alert color="error"><AlertTitle>An error occurred</AlertTitle>{error}</Alert> </Box> :
    <Box display="flex" flexDirection="column" alignItems="center"><CircularProgress className="subvariant-white" size={56} /></Box>

  return (
    <Layout title="Computing platform">
      <Box mt={18} mb={3}>
        <div className="container-horizontal content-center">
          <SvgIcon style={{ height: "148px", width: "auto" }}>
            <LogoLogin />
          </SvgIcon>
        </div>
      </Box>
      {isLoading ? loadingComponent :
        <>
          <Box mt={3} mb={2} display="flex" flexDirection="column" alignItems="center">
            {step === 0 && <LoginSignupForm defaultAuthMode={authMode} form={loginForm} setForm={setLoginForm} setNextDisabled={setNextDisabled} onAuthModeChange={handleAuthModeChanged} />}
            {step === 1 && <VerificationStep email={loginForm.email} validOtp={validOtp} checkPin={checkPin} setOtpValue={setOtpValue} />}
            {step === 2 && <TermsOfServiceStep acceptTerms={acceptTerms} setAcceptTerms={setAcceptTerms} />}
          </Box>
          <Box mb={5} mt={1}>
            <div className="container-horizontal content-center">
              {step > 0 && step < 2 && !checkingPin ? (
                <Box mx={1}>
                  <Button variant="outlined" className="subvariant-white" onClick={() => setStep(0)} size="large">
                    <Box mr={1}>
                      <ArrowLeftWhite />
                    </Box>
                    <Typography>Previous</Typography>
                  </Button>
                </Box>
              ) : (
                <></>
              )}
              {step < 2 && !checkingPin ? (
                <Box mx={1}>
                  <Button variant="outlined" disabled={nextDisabled} onClick={nextStep} size="large">
                    <Typography>Next</Typography>
                    <Box ml={1}>{nextDisabled ? <ArrowRightGray /> : <ArrowRightPurple />}</Box>
                  </Button>
                </Box>
              ) : (
                <></>
              )}
              {checkingPin && <CircularProgress className="subvariant-white" size={56} />}
              {step === 2 ? (
                <Button variant="outlined" disabled={!acceptTerms} onClick={nextStep} size="large">
                  <Typography>Confirm</Typography>
                </Button>
              ) : (
                <></>
              )}
            </div>
            <Box display="flex" alignItems="center" mt={2} flexDirection="column">
              {step === 1 &&
                <Typography color="white" variant="body2">Didn’t get the email? <span onClick={() => resendEmail()} className="inline-link">Resend</span> </Typography>}
              {step === 1 && authMode === 'signup' &&
                <Typography color="white" variant="body2">Have you already signed up? <span onClick={() => {
                  setAuthMode('signin')
                }} className="inline-link">Sign in</span> </Typography>}
            </Box>
          </Box>
        </>
      }

      <Snackbar open={openSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          variant="filled"
          sx={{ width: '100%', borderRadius: '8px' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Layout>
  );
}


export default SigninPage;