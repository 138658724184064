import * as React from 'react';
import {
    Typography,
    SvgIcon
} from '@mui/material';
export interface NoDataIconProps {
    icon: any;
    message?: string;
}

const NoDataIcon = ({...props}: NoDataIconProps) => {

    const { icon, message } = props;

    return (
        <div className={"container-horizontal content-center"}>
            <div className={"container-vertical"}>
                <div className={"container-horizontal content-center"}>
                    <SvgIcon viewBox={"0 0 80 80"}
                             className={"svg-icon-no-data"}>
                        {icon}
                    </SvgIcon>
                </div>
                <Typography variant={"h3"} className={"color-blue1"} sx={{ mb: "2em"}}>
                    {message||''}
                </Typography>
            </div>
        </div>
    );
}

export default NoDataIcon
