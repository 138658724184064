import {Box, IconButton, Paper, Toolbar, Tooltip, Typography} from '@mui/material';

import React, {useEffect, useRef, useState} from 'react';
import {Cancel, Check, Edit, Info} from "@mui/icons-material";
import {ValidatorForm} from "react-material-ui-form-validator";
import DropboxInputForm from "components/src/components/formComponents/dropbox/DropboxInputForm";
import FileTable, {FileItem} from "./FileTable";
import {JobsDataSourceInfoResponse} from '../client';
import {File} from "@bytenite/components/src/components/formComponents/FileExplorer";
import DropboxOutputForm from "@bytenite/components/src/components/formComponents/dropbox/DropboxOutputForm";
import ToolbarCard from './ToolbarCard';

export type FileSelectionCardProps = {
  title: string
  infoText?: string|React.ReactNode
  defaultValue?: File
  dataSourceInfo?: JobsDataSourceInfoResponse
  onChange?: (val: File) => void
  disabled?: boolean
  allowedExtensions?: string[]
  selectOutput?: boolean
}


export default function FileSelectionCard({
                                            title,
                                            infoText,
                                            dataSourceInfo,
                                            defaultValue,
                                            onChange,
                                            disabled = false,
                                            allowedExtensions = ["mp4", "mkv", "mov", "avi"],
                                            selectOutput = false
                                          }: FileSelectionCardProps) {
  const [isEditing, setIsEditing] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File>(undefined)
  const formRef = useRef<any>();

  useEffect(() => {
    if (selectedFile?.id !== defaultValue?.id) {
      setSelectedFile(defaultValue)
    }
  }, [defaultValue]);

  const handleConfirm = async () => {
    if (formRef?.current) {
      const isValid = await formRef.current.validate(selectedFile)
      if (!isValid) {
        return
      }
    }
    setIsEditing(false)
    const updatedFile = {...selectedFile}
    //setSelectedFile(defaultValue)
    if (onChange) {
      onChange(updatedFile)
    }
  }

  const dropboxForm = selectOutput ?
    <DropboxOutputForm onChange={(val) => setSelectedFile(val)} name="source.params"
                       ref={formRef}
                       validators={['dropbox_output']}
                       allowedExtensions={allowedExtensions}
                       rootDirectory={dataSourceInfo?.files}
                       value={selectedFile}/> :
    <DropboxInputForm onChange={(val) => setSelectedFile(val)} name="source.params"
                      ref={formRef}
                      allowedExtensions={allowedExtensions}
                      rootDirectory={dataSourceInfo?.files}
                      value={selectedFile}/>

  const displayFile: FileItem = selectOutput ?
    {filename: defaultValue.name, path: defaultValue.path} :
    {
      filename: dataSourceInfo?.filename,
      size: dataSourceInfo?.size,
      path: dataSourceInfo?.path?.substring(0, dataSourceInfo.path.length - (dataSourceInfo?.filename?.length || 0) - 1)
    }

  const toolbarButtons = []
  if (isEditing) {
    toolbarButtons.push(
      <IconButton onClick={() => handleConfirm()} disabled={disabled} color="success">
        <Check/>
      </IconButton>,
      <IconButton onClick={() => {
        setIsEditing(false)
        setSelectedFile(defaultValue)
        //setInputFile(pendingJob?.dataSource?.params as File)
      }} disabled={disabled} color="error">
        <Cancel/>
      </IconButton>
    )
  } else {
    toolbarButtons.push(
      <Tooltip title="Change file"><IconButton onClick={() => setIsEditing(!isEditing)} disabled={disabled} color="secondary">
        <Edit/>
      </IconButton></Tooltip>
    )
  }

  return <ToolbarCard  title={title} sx={{p: 0}} toolbarButtons={toolbarButtons} infoText={infoText}>
    <Box p={2}>
      {isEditing ?
        <ValidatorForm onSubmit={(formData) => {
        }} style={{width: '100%'}}>
          {dropboxForm}
        </ValidatorForm> :
        <FileTable files={[displayFile]} isOutput={selectOutput}/>}
    </Box>
  </ToolbarCard>

  return <Paper sx={{p: 0}} elevation={3}>
    <Toolbar>
      <Typography variant="h3" color="secondary.dark">
        {title}
      </Typography>
      <Box flexGrow={1}></Box>
      {isEditing ? <>
        <IconButton onClick={() => handleConfirm()} disabled={disabled} color="success">
          <Check/>
        </IconButton>
        <IconButton onClick={() => {
          setIsEditing(false)
          setSelectedFile(defaultValue)
          //setInputFile(pendingJob?.dataSource?.params as File)
        }} disabled={disabled} color="error">
          <Cancel/>
        </IconButton>
      </> : <Tooltip title="Change file"><IconButton onClick={() => setIsEditing(!isEditing)} disabled={disabled} color="secondary">
        <Edit/>
      </IconButton></Tooltip>}
      <IconButton size="small">
        <Info/>
      </IconButton>
    </Toolbar>
    <Box p={2}>
      {isEditing ?
        <ValidatorForm onSubmit={(formData) => {
        }} style={{width: '100%'}}>
          {dropboxForm}
        </ValidatorForm> :
        <FileTable files={[displayFile]} isOutput={selectOutput}/>}
    </Box>
  </Paper>
}
