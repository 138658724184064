import {Box, IconButton, Paper, SxProps, Theme, Toolbar, Tooltip, Typography} from '@mui/material';

import React from 'react';
import {Info} from "@mui/icons-material";


export type ToolbarCardProps = {
  title: string
  children?: React.ReactNode|React.ReactNode[]
  infoText?: string|React.ReactNode
  toolbarButtons?: React.ReactNode[]
  sx?: SxProps<Theme>
}

function ToolbarCard({children, title, infoText, sx, toolbarButtons=[], ...props}: ToolbarCardProps) {
  const [open, setOpen] = React.useState(false);

  const toggleTooltip = (open: boolean) => {
    setOpen(open);
  };

  return <Paper sx={sx} elevation={3}>
    <Toolbar>
      <Typography variant="h3" color="secondary.dark">
        {title}
      </Typography>
      <Box flexGrow={1}></Box>
      {toolbarButtons.map((btn, i) => <React.Fragment key={i}>{btn}</React.Fragment>)}
      {infoText &&
          <Tooltip open={open} onClose={() => toggleTooltip(false)} onOpen={() => toggleTooltip(true)} title={infoText}>
            <IconButton>
                <Info/>
            </IconButton>
          </Tooltip>
      }
    </Toolbar>
    <Box p={2}>
      {children}
    </Box>
  </Paper>
}

export default ToolbarCard
