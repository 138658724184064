import React, {useRef, useState} from 'react';
import Typography from "@mui/material/Typography";
import PageHeader from "@bytenite/components/src/components/layoutComponents/PageHeader";
import {Grid} from "@mui/material";

const Documentation = (props) => {

    const originLocation = window.location.origin;

    return (<>
        <PageHeader title={"Documentation"}></PageHeader>
        <Typography variant={"label"}>The official documentation is going to be published in a next release. Check out some demo videos to learn to use the platform</Typography>
        <Grid mt={3} container spacing={2}>
            <Grid item xs={3}>
                <iframe id="ytplayer" type="text/html" width="100%" height="100%"
                        src={"https://www.youtube.com/embed/ySk_P8uwDy0?autoplay=0&origin="+originLocation}
                        frameBorder="0"/>
            </Grid>
            <Grid item xs={3}>
                <iframe id="ytplayer" type="text/html" width="100%" height="100%"
                              src={"https://www.youtube.com/embed/NR1LuRL7Z5k?autoplay=0&origin="+originLocation}
                              frameBorder="0"/>
            </Grid>
            {/*<Grid item xs={3}>
                <iframe id="ytplayer" type="text/html" width="100%" height="100%"
                        src={"https://www.youtube.com/embed/DZ0iquhj0iU?autoplay=0&origin="+originLocation}
                        frameBorder="0"/>
            </Grid>
            <Grid item xs={3}>
                <iframe id="ytplayer" type="text/html" width="100%" height="100%"
                        src={"https://www.youtube.com/embed/7fbbT8eAp30?autoplay=0&origin="+originLocation}
                        frameBorder="0"/>
            </Grid>*/}
        </Grid>
    </>);
}

export default Documentation;