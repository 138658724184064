import React, {useRef, useState} from 'react';
import Typography from "@mui/material/Typography";
import PageHeader from "@bytenite/components/src/components/layoutComponents/PageHeader";
import HubspotForm from 'react-hubspot-form'
import {InlineWidget, useCalendlyEventListener} from "react-calendly";
import {Box} from "@mui/material";

const Support = (props) => {

    const [formSubmitted, setFormSubmitted] = useState(false)

    return (<>
        <PageHeader title={"Support"}></PageHeader>
        {formSubmitted ? <Box>
            <Typography variant="body1">
                Thank you for reaching out to our support team.
            </Typography>
            <Typography variant="body1">
                We will review your inquiry and respond as soon as possible.
            </Typography>
        </Box> : <Box>
            <Typography variant={"label"}>Fill the form below for any inquiries about encoding jobs and pricing </Typography>
            <HubspotForm
                region='na1'
                portalId='22562360'
                formId='7ce123bf-e176-4499-a6db-c00c614b03cf'
                onSubmit={() => setFormSubmitted(true)}
                onReady={(form) => console.log('Form ready')}
                loading={<div>
                    <Typography variant="h6" color="textSecondary">Loading...</Typography>
                </div>}
            />
        </Box>}
        <Box mt={3}>
            <Typography variant="h5" color="textPrimary" className="color-blue1">Schedule a troubleshooting session</Typography>
        </Box>
        <InlineWidget url="https://calendly.com/bn-dev/troubleshooting-session?primary_color=b027ce" styles={{height: '720px'}} />
    </>);
}

export default Support;
