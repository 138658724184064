import React from 'react';
import {useTheme} from "@mui/material/styles";

interface SvgIconProps {
  color: 'primary'|'secondary'|'error'|'warning'|'info'|'success';
  colorVariant?: 'main'|'light'|'dark'
}

const ArrowsUpDown: React.FC<SvgIconProps> = React.memo(({ color, colorVariant='main' }) => {
  const theme = useTheme()
  const paletteColor = theme.palette[color][colorVariant]
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32"  height="32" viewBox="0 0 32 32"  fill="none">
      <path d="M 11.857609,27.225901 V 8.0539912 l 3.7031,3.4687098 c 0.4688,0.4219 1.1719,0.4219 1.5938,-0.0468 0.4218,-0.4688 0.4218,-1.17191 -0.0469,-1.5937897 l -5.625,-5.25 c -0.4219,-0.4218701 -1.125,-0.4218701 -1.5469002,0 l -5.6250001,5.25 c -0.2344,0.2343797 -0.3281,0.5155897 -0.3281,0.8437897 0,0.2343 0.0937,0.5156 0.2812,0.75 0.4219,0.4687 1.125,0.4687 1.5938,0.0468 l 3.75,-3.4687098 V 27.225901 c 0,0.6093 0.4687003,1.125 1.1250003,1.125 0.6094,0 1.125,-0.5157 1.125,-1.125 z m 8.25,-21.7500397 V 24.600901 l -3.75,-3.4688 c -0.4688,-0.4219 -1.1719,-0.4219 -1.5938,0.0469 -0.4218,0.4687 -0.4218,1.1719 0.0469,1.5937 l 5.625,5.25 c 0.4219,0.4219 1.125,0.4219 1.5469,0 l 5.62499,-5.25 c 0.23437,-0.2343 0.375,-0.5156 0.375,-0.7968 0,-0.2813 -0.14063,-0.5625 -0.32813,-0.7969 -0.42187,-0.4688 -1.125,-0.4688 -1.59375,-0.0469 l -3.70312,3.4688 V 5.4758613 c 0,-0.65625 -0.51559,-1.125 -1.12499,-1.125 -0.6563,0 -1.125,0.46875 -1.125,1.125 z"
            fill={paletteColor}/>
    </svg>
  );
});

export default ArrowsUpDown;
