import * as React from "react";
import {Chip, Typography} from "@mui/material";
import {ReactComponent as ArrowRotate } from "../../assets/arrows-rotate.svg"

const ProgressChip = () => {

    return (
        <Chip label={
            <>
                <Typography><ArrowRotate></ArrowRotate> Progress</Typography>
            </>
        }
              variant="outlined"
              color={"primary"}
        />
    );
}

export default ProgressChip
