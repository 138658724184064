import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
// @ts-ignore
import App from './App';
import env from "./env";
window["process"] = {env: env};
window.global = window;

import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);
