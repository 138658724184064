import React, {useEffect, useState} from "react";
import {Box, Button, Paper, Typography} from "@mui/material";
import {useAuthContext} from "@bytenite/auth/src/hoc/Auth/context";
import Dropbox from "@bytenite/components/src/components/icons/Dropbox";

import {useLocation} from "react-router-dom";
import {useStorage} from "@bytenite/components/src/hoc/Storage/LocalStorage";
import {
  EVENT_JOB_UPLOAD_COMPLETED,
  EVENT_JOB_UPLOAD_PROGRESS,
  EVENT_WALLET_SUBSCRIPTION,
  EVENT_WALLET_TOPUP
} from "../hoc/ApiProvider/Provider";

type AuthPageProps = {}
const AuthPage: React.FC<AuthPageProps> = ({}) => {
  const storage = useStorage()

  const [loading, setLoading] = useState(false);
  const auth = useAuthContext()
  const isAuthenticated = auth.isAuthenticated()


  const queryParams = new URLSearchParams(useLocation().search)

  const fileId = queryParams.get('file_id')


  const handleAuth = async (signUp = false) => {
    setLoading(true)
    await storage.remove('pending-job')
    await storage.setObject('pending-file', {id: fileId, dataSource: 'dropbox'})
    // https://www.dropbox.com/oauth2/authorize?client_id=tgo80nbfbnfsoc7&redirect_uri=http://localhost:5173&response_type=code
    window.location.href = `https://www.dropbox.com/oauth2/authorize?client_id=tgo80nbfbnfsoc7&redirect_uri=${auth.getIdpServerUrl()}/connectors/dropbox&response_type=code`
  }


  return <Box display="flex" flexDirection="column" alignItems="center" pt={4}>
      <Paper elevation={3} sx={{px:4, mt:4, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '280px'}}>
        <Typography variant="h4" align="center" color="secondary.dark">
          Welcome to ByteNite!
        </Typography>
        <Typography variant="subtitle1" align="center" color="primary" gutterBottom>
          Seamless video encoding at your fingertips.
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="center" sx={{maxWidth: '480px'}} gap={2} my={3}>
          <Button variant="contained" color="secondary" size="large" sx={{minWidth: '320px'}} startIcon={<Dropbox color="secondary" colorVariant="light"/>} disabled={loading}
                  onClick={() => handleAuth(false)}>
            Connect your Dropbox account
          </Button>
        </Box>
        <Typography variant="body2" align="center" color="textSecondary" gutterBottom>
          Please connect your Dropbox account to get started.
        </Typography>
      </Paper>
    </Box>

}

export default AuthPage
