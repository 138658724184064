import {
    CommonFilter, CommonFilterCondition,
    Configuration,
    DataSourceDataSource, EventsApi, FetchAPI,
    InfoApi, JobAppParams, JobJob, JobJobState,
    JobsApi,
    JobsDataSourceParams,
    ProtobufAny,
    SchemaApi, StatsApi, WalletApi
} from '../client';
import AuthProvider, {tokenExpiredEvent} from "@bytenite/auth/src/hoc/Auth/Provider";
import {AuthContextInterface, User} from "@bytenite/auth/src/hoc/Auth/context";
import portableFetch from "portable-fetch";


interface HttpDataSource {
    name: string;
    url: string;
    username: string;
    password: string;
}

interface LocalFileDataSource {
    name: string;
    temp_url: string;
    file: string;
}

const sendResponseErrorEvent = (resp: Response, body?: any) => document.dispatchEvent(new CustomEvent('response:error', {
    detail: {
        resp: resp,
        body: body
    }
}))
export const addResponseErrorListener = (callback: (e: CustomEvent<{
    resp: Response,
    body?: any
}>) => void) => document.addEventListener('response:error', callback)
export const removeResponseErrorListener = (callback: (e: CustomEvent<{
    resp: Response,
    body?: any
}>) => void) => document.removeEventListener('response:error', callback)

const fetchApiMod: FetchAPI = (url: string, init?: any) => {
    return portableFetch(url, init).then((resp: Response) => {
        if (resp.status < 300) {
            return resp
        }
        const respClone = resp.clone()
        if (resp.status === 401) {
            tokenExpiredEvent()
            throw resp
        }
        respClone.json().then(body => sendResponseErrorEvent(resp, body)).catch(msg => sendResponseErrorEvent(resp, msg))
        return resp
    })
}

export class ApiService {
    private readonly _infoApi: () => InfoApi
    private readonly _schemaApi: () => SchemaApi
    private readonly _jobsApi: () => JobsApi
    private readonly _walletApi: () => WalletApi
    private readonly _statsApi: () => StatsApi
    private readonly _eventsApi: () => EventsApi

    private readonly _config: Configuration

    private readonly _type_HttpDataSource: string = "type.googleapis.com/bytenite.data_source.HttpDataSource"
    private readonly _type_S3DataSource: string = "type.googleapis.com/bytenite.data_source.S3DataSource"

    private readonly _type_LocalFileDataSource: string = "type.googleapis.com/bytenite.data_source.LocalFileDataSource"
    private readonly _type_ProtobufValue: string = "type.googleapis.com/google.protobuf.Value"
    private readonly _defaultLimitHistory: number = 10
    private readonly _defaultHistoryStates: string[] = ['JOB_STATE_COMPLETE', 'JOB_STATE_FAILED']
    private readonly _defaultQueueStates: string[] = ['JOB_STATE_NEW', 'JOB_STATE_UPLOADING', 'JOB_STATE_PARTITIONING', 'JOB_STATE_PARTITIONED',
        'JOB_STATE_QUEUED', 'JOB_STATE_MERGING', 'JOB_STATE_MERGED', 'JOB_STATE_DOWNLOADING']

    private auth?: AuthContextInterface<User>


    private _user: User
    // @ts-ignore
    #isAuthenticated = false;


    constructor(conf?: Configuration) {
        const basePath = `${conf.basePath}/customer` || `${window.location.protocol}//${window.location.host}`
        const walletBasePath = `${conf.basePath}/wallet` || `${window.location.protocol}//${window.location.host}`
        const authBasePath = `${conf.basePath}/auth` || `${window.location.protocol}//${window.location.host}`
        this._config = new Configuration({
            ...conf,
            basePath: `${conf.basePath}/customer` || `${window.location.protocol}//${window.location.host}`,
            walletBasePath: `${conf.basePath}/wallet` || `${window.location.protocol}//${window.location.host}`
        })

        this._infoApi = () => new InfoApi(this._config, basePath, fetchApiMod)
        this._schemaApi = () => new SchemaApi(this._config, basePath, fetchApiMod)
        this._jobsApi = () => new JobsApi(this._config, basePath, fetchApiMod)
        this._walletApi = () => new WalletApi(this._config, basePath, fetchApiMod, walletBasePath)
        this._statsApi = () => new StatsApi(this._config, basePath, fetchApiMod)
        this._eventsApi = () => new EventsApi(this._config, basePath, fetchApiMod)
    }

    schemaApi() {
        return this._schemaApi()
    }

    jobsApi() {
        return this._jobsApi()
    }

    walletApi(): WalletApi {
        return this._walletApi()
    }

    statsApi(): StatsApi {
        return this._statsApi()
    }

    eventsApi(): EventsApi {
        return this._eventsApi()
    }
  

    getApiKey(): string {
        return this.auth?.getCachedToken()
    }

    setAuth(auth: AuthContextInterface<User>) {
        this.auth = auth
    }

    serverInfo(options?: any) {
        return this._infoApi().customerInfo(options)
    }

    getSchema(id: string) {
        return this._schemaApi().customerGetSchema(id)
    }

    get infoApi() {
        return this._infoApi()
    }

    get isAuthenticated() {
        return this.auth?.isAuthenticated();
    }

    getHttpDataSource(name: string, url: string, username: string, password: string): HttpDataSource {
        return {name: name || "", url: url || "", password: password || "", username: username || ""}
    }

    parseStateToNumber(state: string): number {
        switch (state) {
            case "JOB_STATE_NEW":
                return 0
            case "JOB_STATE_UPLOADING":
                return 1
            case "JOB_STATE_PARTITIONING":
                return 2
            case "JOB_STATE_PARTITIONED":
                return 3
            case "JOB_STATE_QUEUED":
                return 4
            case "JOB_STATE_MERGING":
                return 5
            case "JOB_STATE_MERGED":
                return 6
            case "JOB_STATE_DOWNLOADING":
                return 7
            case "JOB_STATE_COMPLETE":
                return 8
            case "JOB_STATE_FAILED":
                return 255
            default:
                return -1
        }
    }

    getDefaultQueueStates() {
        return this._defaultQueueStates
    }

    getDefaultHistoryStates() {
        return this._defaultHistoryStates
    }

    getDefaultLimitFilters() {
        return this._defaultLimitHistory
    }

    createJobFilter(value: any, field: string, condition: CommonFilterCondition): CommonFilter {
        return {
            field: field,
            condition: condition,
            value:
                {
                    "@type": this._type_ProtobufValue,
                    "value": value
                }
        }
    }

    getQueueJobStateFilters(states: string[], condition: CommonFilterCondition): CommonFilter {
        //the default queue states are mutually exclusive with the history states
        const defaultQueueStates = this._defaultQueueStates
        if (states && states.length > 0) {
            states = states.filter(s => defaultQueueStates.indexOf(s) >= 0)
        } else {
            states = defaultQueueStates
        }
        return this.createJobFilter(states, "state", condition)
    }

    getHistoryJobStateFilters(states: string[], condition: CommonFilterCondition): CommonFilter {
        const defaultHistoryStates = this._defaultHistoryStates
        if (states && states.length > 0) {
            states = states.filter(s => defaultHistoryStates.indexOf(s) >= 0)
        } else {
            states = defaultHistoryStates
        }
        return this.createJobFilter(states, "state", condition)
    }

    getJobsDataSourceParams(dataSource: DataSourceDataSource, dataDestination: DataSourceDataSource): JobsDataSourceParams {
        return {dataSource: dataSource, dataDestination: dataDestination}
    }

    getDataSourceDataSource(dataSourceDescriptor: string, source: any): DataSourceDataSource {
        //const httpDataSource = this.apiService.getHttpDataSource("", source.url, "", "")
        let params = null
        let dataSourceType = null
        switch (dataSourceDescriptor) {
            case 'file':
                dataSourceType = this._type_LocalFileDataSource
                params = {name: source.file, temp_url: source.temp_url || null}
                break
            case 'gcp':
                // const httpDataDestination = this.apiService.getHttpDataSource("", destination.url, "", "")
                dataSourceType = this._type_S3DataSource //TODO: cloud bucket
                params = {...source}
                break
            case 'bucket':
                // const httpDataDestination = this.apiService.getHttpDataSource("", destination.url, "", "")
                params = this.getHttpDataSource("", source.url, "", "")
                dataSourceType = this._type_HttpDataSource //TODO: cloud bucket
                break
            default:
                params = this.getHttpDataSource("", source.url, "", "")
                dataSourceType = this._type_HttpDataSource

        }
        return {
            dataSourceDescriptor: dataSourceDescriptor,
            params: {"@type": dataSourceType, ...params}
        }
    }

    getJobAppParams(selectedPreset: string, data: any, schema: string): JobAppParams {
        return {data: data, schema: schema, preset: selectedPreset}
    }

    getHtmlFormDataFromDataSource(job: JobJob): any {
        return {source: job.dataSource, destination: job.dataDestination}
        //TODO: remove old logic
        let dataSource = {}
        let dataDestination = {}
        switch (job.dataSource?.params["@type"]) {
            case this._type_HttpDataSource:
                dataSource = {
                    source_type: job.dataSource.dataSourceDescriptor,
                    url: job.dataSource.params.url
                }
                break
            case this._type_LocalFileDataSource:
                dataSource = {
                    source_type: job.dataSource.dataSourceDescriptor,
                    file: job.dataSource.params.name,
                    tempUrl: job.dataSource.params.tempUrl,
                }
                break
        }
        switch (job.dataDestination?.params["@type"]) {
            case this._type_HttpDataSource:
                dataDestination = {
                    destination_type: job.dataDestination.dataSourceDescriptor,
                    url: job.dataDestination.params.url
                }
                break
            case this._type_S3DataSource:
                dataDestination = {
                    source_type: job.dataDestination.dataSourceDescriptor,
                    ...job.dataDestination.params
                }
                break
        }
        return {source: dataSource, destination: dataDestination}
    }

}

export default ApiService
