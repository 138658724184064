import React, {ReactNode, useContext, useEffect, useState} from 'react';
import Sidebar from '@bytenite/components/src/components/layoutComponents/Sidebar';
import {
  Box,
  Container,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import AppBar from '@bytenite/components/src/components/layoutComponents/AppBar';
import AuthContext from '@bytenite/auth/src/hoc/Auth/context';

interface SidebarProps {
  updateSidebarOpen?: () => void;
  sidebarOpen: boolean;
}

interface LayoutProps {
  children: ReactNode;
  sidebar?: SidebarProps;
  addSidebarEventListener?: () => void;
  removeSidebarEventListener?: () => void;
}

const Layout: React.FC<LayoutProps> = ({
                                         children,
                                         sidebar = {
                                           updateSidebarOpen: undefined,
                                           sidebarOpen: false,
                                         },
                                         ...props
                                       }) => {
  const theme = useTheme();
  // @ts-ignore
  const keepOpen = useMediaQuery(theme.breakpoints.up(theme.sidebar?.breakpoint));
  const [sidebarOpen, updateSidebarOpen] = useState<boolean>(keepOpen);
  const authCtx = useContext(AuthContext);
  const [authUser, setAuthUser] = useState<any>(null);

  const updateAuthUser = () => setAuthUser(authCtx.getUserInfo());

  useEffect(() => {
    authCtx.onTokenUpdate(updateAuthUser)
    updateAuthUser()
    return () => {
      authCtx.removeTokenUpdateListener(updateAuthUser)
    }
  }, []);


  return (
    <Box display="flex">
      <Sidebar
        updateSidebarOpen={updateSidebarOpen}
        sidebarOpen={sidebarOpen}
        addSidebarEventListener={props.addSidebarEventListener}
        removeSidebarEventListener={props.removeSidebarEventListener}
      />
      <Container className="layout" component="main">
        <AppBar
          sidebarOpen={sidebarOpen}
          color="primary"
          logout={authCtx.signOut}
          userFullName={authUser ? authUser.email : 'user'}
        ></AppBar>
        <Toolbar/>
        <Box component="main" sx={{flexGrow: 1}}>
          {children}
        </Box>
      </Container>
    </Box>
  );
};

export default Layout;
