import {
  Alert,
  Box,
  BoxProps,
  Button,
  IconButton, MenuItem, Select, SelectChangeEvent, Snackbar,
  TextField, Theme,
  Typography
} from '@mui/material';

import React, {useEffect, useState} from 'react';
import {ContentCopy} from "@mui/icons-material";
import {AuthContextInterface, User} from "@bytenite/auth/src/hoc/Auth/context";
import ApiKeysTable from "./ApiKeysTable";
import OtpInput from "@bytenite/components/src/components/formComponents/otpInput/OtpInput";
import {AuthSignInResponse} from "@bytenite/auth/src/client";
import {withStyles, WithStyles} from "@mui/styles";

interface DataRow {
  key: string;
  name: string;
  issuedAt: string;
  expiresAt: string;
}


export interface ApiKeysProps extends WithStyles<typeof styles> {
  box?: BoxProps
  userEmail: string
  auth: AuthContextInterface<User>
}

const styles = (theme: Theme) => ({
  root: {

  },
  buttonGroup: {
    ['& .MuiButton-root']: {
      margin: theme.spacing(1)
    }
  },
  alert: {
    flexGrow: 1
  }
})


const DURATION_HOUR = 60 * 60;
const DURATION_DAY = 24 * DURATION_HOUR;
const DURATION_WEEK = 7 * DURATION_DAY;
const DURATION_MONTH = 30 * DURATION_DAY;
const DURATION_SIX_MONTHS = 6 * DURATION_MONTH;
const DURATION_YEAR = 365 * DURATION_DAY;

type Duration = typeof DURATION_HOUR | typeof DURATION_DAY | typeof DURATION_WEEK |
  typeof DURATION_MONTH | typeof DURATION_SIX_MONTHS | typeof DURATION_YEAR | null;

const apiKeyDurations = [
  { label: '24 Hours', value: DURATION_DAY },
  { label: '1 Week', value: DURATION_WEEK },
  { label: '1 Month', value: DURATION_MONTH },
  { label: '6 Months', value: DURATION_SIX_MONTHS },
  { label: '1 Year', value: DURATION_YEAR },
  { label: 'No Expiration', value: null },
];

function ApiKeys({box, auth, userEmail, classes, ...props}: ApiKeysProps) {
  const boxProps = {...box}

  const [apiKeys, setApiKeys] = useState([])

  const [otpValue, setOtpValue] = useState("")
  const [apiKeyName, setApiKeyName] = useState<string>("")
  const [apiKeyDuration, setApiKeyDuration] = useState<Duration>(DURATION_SIX_MONTHS)
  const [pendingApiKeyRequest, setPendingApiKeyRequest] = useState<AuthSignInResponse>(null)
  const [apiKey, setApiKey] = useState<string>("")
  const [apiKeyError, setApiKeyError] = useState<string>("")
  const [notification, setNotification] = useState({open: false, text: ""})

  const showNotification = (text: string) => {
    setNotification({open: true, text: text})
  }
  const hideNotification = () => {
    setNotification({open: false, text: ''})
  }

  const handleApiKeyDurationChange = (event: SelectChangeEvent<Duration>) => {
    const selectedValue = event.target.value as Duration;
    setApiKeyDuration(selectedValue);
  };

  const requireApiKey = () => {
    setApiKeyName("")
    setOtpValue("")
    setApiKeyError("")
    auth.requestSignInCode(userEmail).then(resp => {
      setPendingApiKeyRequest(resp)
    }).catch((err) => {
      //setPendingApiKeyRequest(null)
      setApiKeyError(err.message||err.toString())
    })
    /**/
  }
  const generateApiKey = (code: string) => {
    setApiKey(null)
    auth.getApiKey({code: code, name: apiKeyName||'API key', duration: `${apiKeyDuration.toString()}s`, nonce: pendingApiKeyRequest.nonce}).then(resp => {
      setPendingApiKeyRequest(null)
      setApiKey(resp.token)
      loadApiKeys()
    }).catch((err) => {
      setPendingApiKeyRequest(null)
      setApiKeyError(err.message||err.toString())
    })
  }

  const loadApiKeys = () => {
    auth.getApiKeys().then(resp => {
      setApiKeys(resp)
    })
  }

  const revokeApiKey = (id: string) => {
    return auth.revokeApiKey(id).then(() => {
      loadApiKeys()
    })
  }

  const copyValue = (value: string) => {
    navigator.clipboard.writeText(value)
    showNotification("Copied to clipboard")
  }

  useEffect(() => {
    loadApiKeys()
  }, [auth])

  return (
    <Box {...boxProps} className={"container-vertical content-center"}>
      <div className={"container-header-space-between"}>
        <Typography variant={"h3"} className={"color-blue1"} style={{lineHeight: 2}}>
          Api keys
        </Typography>
        <Button variant="text" color="primary" onClick={() => requireApiKey()}>New API key</Button>
      </div>
      <Box className={"container-vertical content-start"} m={2}>
        {pendingApiKeyRequest && <Alert severity="info">
            <Typography variant="body1">
                We've sent an email with the verification code to {userEmail}
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Box mt={2}>
                    <TextField required defaultValue={apiKeyName} onChange={(e) => setApiKeyName(e.target.value)} label="Key name" helperText={<Typography variant="subtitle2">Insert a mnemonic name for your API key</Typography>}/>
                </Box>
                <Box mt={2}>
                    <Select value={apiKeyDuration} onChange={handleApiKeyDurationChange} fullWidth>
                      {apiKeyDurations.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography variant="subtitle2">Select the api key duration</Typography>
                </Box>
                <Box my={2} display="flex" flexDirection="column">
                    <OtpInput color={'primary'} digits={6}
                              setOtpValue={(value: string) => {
                                setOtpValue(value);
                              }} isValid={true}/>
                    <Typography variant="subtitle2" pl={2}>
                        Insert the verification code
                    </Typography>
                </Box>
                <Box>
                    <Button variant="text" color="primary" disabled={otpValue.length !== 6 || apiKeyName === ""} onClick={() => generateApiKey(otpValue)}>Generate API Key</Button>
                </Box>
            </Box>

        </Alert>}
        {apiKey && <Alert severity="success" sx={{width: '100%', flexGrow:1}} classes={{message: classes.alert}}>
            <Box pb={1} flexGrow={1} sx={{width: '100%'}}>
                <Typography variant="body1">
                    API key successfully generated (One-time access)
                </Typography>
                <Box display="flex" mt={2} width="90%">
                    <TextField label="API key" value={apiKey} color="primary" sx={{flexGrow: 1}} fullWidth/>
                    <IconButton size="small" color="secondary" sx={{mx: 0}} onClick={() => copyValue(apiKey)}><ContentCopy fontSize="inherit"/></IconButton>
                </Box>
            </Box>
        </Alert>}
        {apiKeyError && <Alert severity="error">
            <Typography variant="body1">
                Invalid verification code
            </Typography>
            <Typography variant="body2">
              {apiKeyError}
            </Typography>
        </Alert>}
      </Box>
      <div className={"container-vertical content-start"}>
        <ApiKeysTable box={{mx: 2, mt: 1, mb: 4}} data={apiKeys} onApiKeyRevoke={(id) => revokeApiKey(id)}/>
      </div>
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        open={notification.open}
        autoHideDuration={3000}
        onClose={hideNotification}
        message={notification.text}
      />
    </Box>
  );
}

// @ts-ignore
export default withStyles(styles)(ApiKeys)
