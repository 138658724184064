import React from 'react';
import Icon, {SvgIconProps} from "./Icon";


const Dropbox: React.FC<Omit<SvgIconProps, 'path'>> = React.memo((props) => {
  return <Icon translateY="1px" viewBox="0 0 24 24" path="m3 6.2l5 3.19l5-3.19L8 3L3 6.2m10 0l5 3.19l5-3.19L18 3l-5 3.2M3 12.55l5 3.19l5-3.19l-5-3.2l-5 3.2m15-3.2l-5 3.2l5 3.19l5-3.19l-5-3.2M8.03 16.8l5.01 3.2l5-3.2l-5-3.19l-5.01 3.19Z"
               {...props}/>
});

export default Dropbox;
