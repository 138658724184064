import {Box, BoxProps, Button, CircularProgress, IconButton, Theme, Typography} from "@mui/material";
import React from "react";

import {ValidatorComponent, ValidatorComponentProps} from "react-material-ui-form-validator";
import {Cancel, Error} from "@mui/icons-material";
import {ClassNameMap, withStyles} from "@mui/styles";


const styles = (theme: Theme) => ({
  root: {},
  pendingUpload: {
    border: `2px dashed ${theme.palette.secondary.light}`,
    borderRadius: theme.spacing(1),
    backgroundColor: `rgba(0, 0, 0, 0.01)`
  },
})

export interface FileInputFormProps extends BoxProps {
  onChange?: (formData: any) => any;
  onCancel?: (isUploading: boolean) => any;
  isUploading: boolean;
  classes?: ClassNameMap;
  value: {
    name?: string
  }
}

const getReadableFileSizeString = (fileSizeInBytes: number,) => {
  let i = -1;
  const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    fileSizeInBytes /= 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

export class FileInputForm extends ValidatorComponent {
  props: ValidatorComponentProps & FileInputFormProps

  constructor(props: ValidatorComponentProps & FileInputFormProps) {
    super(props);
    this.props = props;
  }

  renderValidatorComponent() {
    const {value, classes, isUploading = false, errorMessages, validatorListener, onChange, ...rest} = this.props;
    if (isUploading) {
      return <Box {...rest} px={2} display="flex" flexDirection="row" alignItems="center" className={classes?.pendingUpload}>
        <Box my={2} mr={2}>
          <CircularProgress></CircularProgress>
        </Box>
        <Typography variant="subtitle2">Upload in progress...</Typography>
        <Box flexGrow="1" display="flex" justifyContent="end">
          <IconButton onClick={() => this.onCancel(true)} title="Cancel upload" color="error"><Cancel></Cancel></IconButton>
        </Box>
      </Box>
    }
    if (value?.name) {
      return <>
        <Box {...rest} display="flex" alignItems="center" flexDirection="row">
          <Typography variant="subtitle1">{value.name}</Typography>
          <Box mx={1}><Typography variant="subtitle2" color="textSecondary">({getReadableFileSizeString(value.size)})</Typography></Box>
          <Box>
            <IconButton onClick={() => {
            this.onFileChange(null)
          }} size="small" color="error"><Cancel/></IconButton>
          </Box>
        </Box>
        {this.errorText()}
      </>
    }
    return (
      <>
        <Box {...rest} display="flex" alignItems="center">
          <Button variant="contained" component="label" color="primary" onChange={(e) => {
            this.onFileChange(e)
          }}>
            {" "}
            Upload a file
            <input type="file" accept="video/mp4,video/x-m4v,video/*" hidden/>
          </Button>
      </Box>
        {this.errorText()}
      </>
    );
  }

  onFileChange(e: any) {

    const file = e && e.target.files && e.target.files.length > 0 ? e.target.files[0] : null
    if (this.props.onChange) {
      this.props.onChange(file)
    }
  }

  onCancel(isUploading: boolean) {
    if (this.props.onCancel) {
      this.props.onCancel(isUploading)
    }
  }

  getErrorMessage() {
    return "this field is required"
  }

  errorText() {
    const isValid = this.isValid()
    if (isValid) {
      return null;
    }

    return (
      <Box mt={2}>
        <Typography variant="subtitle2" color="error"><Error color="error" fontSize="small" sx={{transform: 'translateY(2px)'}}/> {this.getErrorMessage()}</Typography>
      </Box>
    );
  }
}


const StyledFileInputForm = withStyles(styles)(FileInputForm)
export default StyledFileInputForm
