import React from 'react';
import {Box, Chip, ChipProps, FormControl, FormLabel, SxProps, Theme, Typography} from "@mui/material";

import {Variant} from "@mui/material/styles/createTypography";
import {TypographyProps} from "@mui/material/Typography/Typography";
import {Option} from "../../types/utils";

type Color = TypographyProps['color']

export interface Stringer {
  toString: () => string
}

export interface ChipSelectControlProps<T extends Stringer, U extends Option<T>> {
  id?: string;
  options?: U[]
  onChange?: (e?: U) => void;
  required?: boolean;
  label?: string;
  value?: T;
  labelMargin?: number;
  className?: string;
  variantFormLabel?: Variant;
  colorFormLabel?: Color;

  chipColor?: 'primary' | 'secondary' | 'error';
  size?: ChipProps['size']
  prefixIcon?: React.ReactElement;
  suffixIcon?: React.ReactElement;
}

const ChipSelect = <T extends Stringer, U extends Option<T>>({
                                            options = [],
                                            className,
                                            required,
                                            labelMargin,
                                            id,
                                            label,
                                            value,
                                            onChange,
                                            variantFormLabel = 'subtitle1',
                                            colorFormLabel = 'inherit',
                                            chipColor = 'primary',
                                            size = 'medium',
                                            prefixIcon,
                                            suffixIcon,
                                            ...props
                                          }: ChipSelectControlProps<T, U>) => {

  const chipStyle:  React.CSSProperties = {}
  if (suffixIcon) {
    chipStyle.textOverflow = 'initial!important'
  }

  return (
    <FormControl fullWidth className={className || ''}>
      <FormLabel required={required} htmlFor={id}>
        {label &&
            <Typography mx={labelMargin || 0} variant={variantFormLabel} color={colorFormLabel}>
              {label}
            </Typography>}
      </FormLabel>
      <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center" gap={1}>
        {options?.map((o: U) => <Chip style={chipStyle} onClick={() => onChange && onChange({...o})} size={size} color={chipColor}
                                 variant={o.value.toString() === value?.toString() ? 'filled' : 'outlined'} icon={prefixIcon} key={o.value.toString()} label={<>{o.label}{suffixIcon && <span>{suffixIcon}</span>}</>}/>)}
        <input type="hidden" value={(value || '').toString()}/>
      </Box>
    </FormControl>
  );
};

export default ChipSelect;
