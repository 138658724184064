import React, {useCallback, useEffect, useState} from "react";
import {Box, Chip, Grid, Typography} from "@mui/material";
import {Error} from "@mui/icons-material";

import StorjCredentials from "./StorjCredentials";
import StorjClient from "./client";
import {Bucket, Object as S3Object} from "aws-sdk/clients/s3";
import {ValidatorComponent, ValidatorComponentProps, ValidatorForm} from "react-material-ui-form-validator";
import StorjFileSelection from "./StorjFileSelection";


export interface StorjFormData  {
  bucket_name: string
  name: string
  temp_url?: string
}
export interface StorjFormProps {
  onChange?: (formData: StorjFormData) => void;
  value: {
    bucket_name?: string
    name?: string
  }
}

ValidatorForm.addValidationRule('storj', (value) => {
  return value.bucket_name && value.name;
});

const StorjForm = ({onChange, value}: StorjFormProps) => {
  const [storjClient, setClient] = useState<StorjClient|undefined>()
  const [selectedBucket, setSelectedBucket] = useState<string>(value?.bucket_name||'')
  const [buckets, setBuckets] = useState<Array<Bucket>>([])
  const [bucketFiles, setBucketFiles] = useState<Array<S3Object>>([])
  const [selectedFile, setSelectedFile] = useState<string>(value?.name||'')

  const selectBucket = useCallback((bucket_name = '') => {
    setBucketFiles([])
    if (!bucket_name) {
      return
    }
    if (!storjClient) {
      return
    }
    storjClient.listFiles(bucket_name).then(resp => {
      setBucketFiles(resp.Contents||[])
    })
  }, [storjClient])

  const selectFile = useCallback((fileName = '') => {
    if (!fileName) {
      setSelectedFile('')
    }
    setSelectedFile(fileName)
    if (onChange) {
      storjClient?.listBuckets()
      onChange({bucket_name: selectedBucket, name: fileName, temp_url: storjClient?.getSignedURL(selectedBucket, fileName)})
    }
  }, [onChange, selectedBucket, storjClient])

  useEffect(() => {
    if (!storjClient) {
      return
    }
    storjClient.listBuckets().then(bucketsList => {
      setBuckets(bucketsList.Buckets||[])
    })
  }, [storjClient])
  useEffect(() => {
    if (!selectedBucket || !storjClient) {
      return
    }
    selectBucket(selectedBucket)
  }, [selectBucket, selectedBucket, storjClient])

  if (selectedFile) {
    return <Grid spacing={1} flexDirection="row" alignItems="center" container>
      <Grid item>
        <Box>
          <Typography variant="subtitle2">Selected file</Typography>
        </Box>
      </Grid>
      <Grid item>
        <Chip
          label={`${selectedBucket}/${selectedFile}`}
          variant="outlined"
          color="primary"
          onDelete={() => {
            setSelectedBucket('')
            setSelectedFile('')
            if (onChange) {
              onChange({bucket_name: '', name: ''})
            }
          }}
        />
      </Grid>
    </Grid>
  }

  if (!storjClient) {
    return <StorjCredentials onSubmit={(c) => {
      setClient(c)
    }}/>
  }

  return <StorjFileSelection buckets={buckets} selectedBucketFiles={bucketFiles} onBucketSelect={selectBucket} onFileSelect={selectFile}/>
}

export class StorjFormValidatorElement extends ValidatorComponent {
  readonly props: ValidatorComponentProps & StorjFormProps


  constructor(props: ValidatorComponentProps) {
    super(props);
    this.props = props;
  }

  renderValidatorComponent() {
    return (
      <Box>
        <StorjForm value={this.props.value} onChange={(formData) => this.onFormChange(formData)}/>
        {this.errorText()}
      </Box>
    );
  }
  onFormChange(formData: StorjFormData) {
    if (this.props.onChange) {
      this.props.onChange(formData)
    }
  }

  errorText() {
    const isValid  = this.isValid();

    if (isValid) {
      return null;
    }

    return (
      <Box mt={2}>
        <Typography variant="subtitle2" color="error"><Error color="error" fontSize="small" sx={{transform: 'translateY(2px)'}}/> {this.getErrorMessage()}</Typography>
      </Box>
    );
  }
}

export default StorjForm
