import {Box, BoxProps, Grid, MenuItem} from "@mui/material";
import React, {useState} from "react";

import {SelectValidator, TextValidator} from "react-material-ui-form-validator";
import {S3DataSource} from "../../../types/JobDataSource";
import SecretSelector from "../SecretSelector";
import {useTheme} from "@mui/material/styles";
import {Secret} from "../JobDataForm/types";

export interface S3OutputFormProps extends BoxProps {
  prefix: string;
  onChange?: (value: any, key?: string) => any;
  cloudRegions?: Array<string>
  value: S3DataSource
  secrets?: Secret[]
  onAddSecret?: () => void
}

const defaultInputProps = {
  validators: ['required'],
  errorMessages: ['this field is required'],
  fullWidth: true,
}

const S3OutputForm = ({onChange, prefix, value, cloudRegions, secrets=[], onAddSecret, ...props}: S3OutputFormProps) => {
  const [selectedSecret, setSelectedSecret] = useState<string>("")
  const handleSelectSecret = (id: string) => {
    setSelectedSecret(id)
  }

  return <Box {...props} display="flex" alignItems="end">
    <Grid spacing={1} container>
{/*      <Grid item xs={12} sm={6}>
        <TextValidator {...defaultInputProps} name={`${prefix}.access_key`} label="Access key" value={value.access_key || ''}
                       onChange={(e: React.FormEvent<{ value: string }>) => onChange && onChange((e.target as HTMLInputElement).value, 'access_key')}/>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextValidator {...defaultInputProps} name={`${prefix}.secret_key`} label="Secret key" value={value.secret_key || ''}
                       onChange={(e: React.FormEvent<{ value: string }>) => onChange && onChange((e.target as HTMLInputElement).value, 'secret_key')}/>
      </Grid>*/}
      <Grid item xs={12} sm={6}>
        {cloudRegions ?
          <SelectValidator {...defaultInputProps} name={`${prefix}.cloud_region`} label="Cloud region" value={value.cloud_region || ''}
                           onChange={(e: React.FormEvent<{ value: string }>) => onChange && onChange((e.target as HTMLInputElement).value, 'cloud_region')}>
            {cloudRegions.map(val => <MenuItem key={val} value={val}>{val}</MenuItem>)}
          </SelectValidator> :
          <TextValidator {...defaultInputProps} name={`${prefix}.cloud_region`} label="Cloud region" value={value.cloud_region || ''}
                         onChange={(e: React.FormEvent<{ value: string }>) => onChange && onChange((e.target as HTMLInputElement).value, 'cloud_region')}/>}
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextValidator {...defaultInputProps} name={`${prefix}.bucket_name`} label="Bucket name" value={value.bucket_name || ''}
                       onChange={(e: React.FormEvent<{ value: string }>) => onChange && onChange((e.target as HTMLInputElement).value, 'bucket_name')}/>
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextValidator {...defaultInputProps} name={`${prefix}.name`} label="File path" value={value.name || ''} onChange={(e: React.FormEvent<{ value: string }>) => onChange && onChange((e.target as HTMLInputElement).value, 'name')}/>
      </Grid>
      <Grid item xs={12}>
        <SecretSelector onSelect={handleSelectSecret} value={selectedSecret} secrets={secrets} onAddNew={onAddSecret}/>
      </Grid>
    </Grid>
  </Box>
}

export default S3OutputForm
