import * as React from "react";
import {Chip, Typography} from "@mui/material";
import {ReactComponent as CircleXMark } from "../../assets/circle-xmark.svg"

const ErrorChip = () => {

    return (
        <Chip label={
            <>
                <Typography><CircleXMark></CircleXMark> Failed</Typography>
            </>
        }
              variant="outlined"
              color={"error"}
        />
    );
}

export default ErrorChip
