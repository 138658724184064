import {Alert, Box, BoxProps} from '@mui/material';

import React, {useState} from 'react';

import {loadStripe} from "@stripe/stripe-js";
import CreateSubscription from "../CreateSubscription";
import StripeForm from "./StripeForm";
import {PricingPlan} from "../../types/utils";

export type TopUpParams = {
  currencyAmount: number,
  amount: number,
  clientSecret: string,
  currency: string
}

export type TopUpFormProps = {
  box?: BoxProps,
  onTopUpRequest: (currency: string, amount: number) => Promise<TopUpParams>,
  plans: PricingPlan[]
  subscriptionPlans: PricingPlan[]
  onChangePlanType?: (value: number) => void
}


const stripeKey = document.querySelector('meta[name="stripe-key"]')?.getAttribute('content') || '...'
if (!stripeKey) {
  throw new Error(`No stripe key (${stripeKey})`)
}
loadStripe(stripeKey);

export default function TopUpForm({box, onTopUpRequest, onChangePlanType, plans, subscriptionPlans}: TopUpFormProps) {
  const boxProps = {...box}
  const [currentStep, setCurrentStep] = useState(0)
  const [topUpParams, setTopUpParams] = useState<TopUpParams | undefined>()

  const handleConfirm = (value: number) => {
    if (value) {
      onTopUpRequest('USD', value).then(topUpParams => {
        setTopUpParams(topUpParams)
        setCurrentStep(1)
      })
    }
  }


  switch (currentStep) {
    case 0:
      return <CreateSubscription
        variant="topup" plans={plans} subscriptionPlans={subscriptionPlans} onConfirm={handleConfirm} onChangePlanType={onChangePlanType}/>
    case 1:
      if (topUpParams) {
        return <StripeForm paymentParams={topUpParams}/>
      }
    // eslint-disable-next-line no-fallthrough
    default:
      return (<Box className="TopUpForm" {...boxProps} justifyContent="center" display="flex" flexDirection="column" alignItems="center">
        <Alert color="error">An error occurred</Alert>
      </Box>)
  }
}

