import * as React from 'react';
import {Box, Button, Typography} from '@mui/material';
import './_layouts.scss';
import {ReactNode} from "react";

export interface PageHeaderProps {
    title: string;
    createNewJob?: (v: boolean) => void;
    buttons?: ReactNode[]
}

const PageHeader = ({...props}: PageHeaderProps) => {
    const {title, createNewJob, buttons=[]} = props
    return (
        <div className="page-header">
            <Typography variant={"h2"} className="color-blue1">
                {title}
            </Typography>
            {createNewJob ?
                <Button onClick={ () => createNewJob(false) } size={"large"}
                    color="primary" variant="contained">+ New job</Button> : <></>}
          {buttons.length ?
              <Box>
                {buttons.map((button, i) => <React.Fragment key={i}>{button}</React.Fragment>)}
              </Box> : <></>
          }
        </div>
    );
}

export default PageHeader
