import React, {useContext} from 'react';
import {
    AuthAuthenticationResponse,
    AuthLoginChallengeResponse, AuthSignInRequest, AuthSignInResponse,
    AuthTokenRequest,
} from "../../client";

export interface User {
    email: string;
    displayName: string;
    role?: string;
}

export interface IdpContextInterface<T extends User> {
    signInWithMail: (body: AuthTokenRequest) => Promise<AuthAuthenticationResponse>;
    requestSignInCode: (signInForm: AuthSignInRequest) => Promise<AuthSignInResponse>
    skipLogin: (loginChallenge: string) => Promise<AuthLoginChallengeResponse>
    getUserInfo: () => Promise<User>
}

const IdpContext = React.createContext<IdpContextInterface<any>|null>(null);

export const useIdpContext = () => useContext(IdpContext)

export default IdpContext;
