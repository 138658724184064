import React, {useEffect, useState} from 'react';
import PageHeader from "@bytenite/components/src/components/layoutComponents/PageHeader";
import {Box, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import {withApi} from "@/hoc/ApiProvider/Provider";
import {withAuth} from "@bytenite/auth/src/hoc/Auth/Provider";
import ApiKeys from "../components/ApiKeys";
import SecretsTable from "@/components/SecretsTable";

const Profile = ({api, auth, ...props}) => {

    const [userEmail, setUserEmail] = useState("")

    useEffect(() => {
        setUserEmail(auth.getUserInfo().email)
    }, [])


    return (
        <Box>
            <PageHeader title={"Your account"}></PageHeader>
            <Box sx={{width: "400px"}}>
                <Paper elevation={3}>
                    <div className={"container-vertical content-start"}>
                        <div className={"container-header-space-between"}>
                            <Typography variant={"h3"} className={"color-blue1"}>
                                {"Profile info"}
                            </Typography>
                        </div>
                        <div className={"container-horizontal content-start"}>
                            <div className={"container-header-space-between"}>
                                <Typography variant={"label"}>
                                    {"Your email is:"}
                                </Typography>
                                <Box ml={1}>
                                    <Typography variant={"label"}><b>{userEmail}</b></Typography>
                                </Box>
                            </div>
                        </div>
                    </div>
                </Paper>
            </Box>
            <Box sx={{width: "100%", mt: 4}}>
                <Paper elevation={3}>
                    <ApiKeys auth={auth} userEmail={userEmail}/>
                </Paper>
            </Box>
            <Box sx={{width: "100%", mt: 4}}>
                <Paper elevation={3}>
                    <SecretsTable auth={auth} userEmail={userEmail}/>
                </Paper>
            </Box>
        </Box>
    );
}

export default withApi(withAuth(Profile));
