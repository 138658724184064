import React from 'react';
import Icon, {SvgIconProps} from "./Icon";


const Documentation: React.FC<Omit<SvgIconProps, 'path'>> = React.memo((props) => {
    return <Icon translateY="5px" translateX="5px" viewBox="0 0 24 24" path="M14 11.5V1.5C14 0.6875 13.3125 0 12.5 0H2.5C1.09375 0 0 1.125 0 2.5V14C0 15.125 0.875 16 2 16H13.5C13.75 16 14 15.7812 14 15.5C14 15.25 13.75 15 13.5 15H13V12.9375C13.5625 12.7188 14 12.1562 14 11.5ZM1 2.5C1 1.6875 1.65625 1 2.5 1H3V12H2C1.625 12 1.28125 12.125 1 12.2812V2.5ZM12 15H2C1.4375 15 1 14.5625 1 14C1 13.4688 1.4375 13 2 13H12V15ZM12.5 12H4V1H12.5C12.75 1 13 1.25 13 1.5V11.5C13 11.7812 12.75 12 12.5 12ZM11 4H6C5.71875 4 5.5 4.25 5.5 4.5C5.5 4.78125 5.71875 5 6 5H11C11.25 5 11.5 4.78125 11.5 4.5C11.5 4.25 11.25 4 11 4ZM11 7H6C5.71875 7 5.5 7.25 5.5 7.5C5.5 7.78125 5.71875 8 6 8H11C11.25 8 11.5 7.78125 11.5 7.5C11.5 7.25 11.25 7 11 7Z"
                 {...props}/>
});

export default Documentation;
