import * as React from "react";
import {Chip, Typography} from "@mui/material";
import {ReactComponent as CircleCheck } from "../../assets/circle-check.svg"

const CompletedChip = () => {

    return (
        <Chip label={
            <>
                <Typography><CircleCheck></CircleCheck> Completed</Typography>
            </>
        }
              variant="outlined"
              color={"success"}
        />
    );
}

export default CompletedChip
