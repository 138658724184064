import * as React from "react";
import {Chip, Typography} from "@mui/material";
import {ReactComponent as CloudPlus } from "../../assets/cloud-plus.svg"

const CreatedChip = () => {

    return (
        <Chip label={
            <Typography><CloudPlus></CloudPlus> Not Started</Typography>
        }
              variant="outlined"
              color={"secondary"}
        />
    );
}

export default CreatedChip
