import * as React from 'react';
import { IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ReactComponent as ArrowLeftGray } from "../../assets/pagination/arrow-left-gray.svg";
import { ReactComponent as ArrowLeftPink } from "../../assets/pagination/arrow-left-pink.svg";
import { ReactComponent as ArrowRightGray } from "../../assets//pagination/arrow-right-gray.svg";
import { ReactComponent as ArrowRightPink } from "../../assets/pagination/arrow-right-pink.svg";
import { ArrowDownFromLine } from 'lucide-react';


export interface Pagination {
    total: number;
    offset: number;
    hasPrevious: boolean;
    hasNext: boolean;
}

export interface PaginationDataProps {
    limit: number;
    pagination: Pagination;
    onPageNumberClick: any;
}

const PaginationData = ({ ...props }: PaginationDataProps) => {
    const { pagination, onPageNumberClick } = props
    let limit = props.limit
    const pageButton = (pageNum: number, isSelected: boolean, next: number) => {
        let returnString = "" + pageNum
        if (next - pageNum > 1 && next != -1) {
            returnString = returnString + "  ..."
        }
        return <IconButton key={"page" + pageNum} onClick={() => onPageNumberClick(pageNum)}>
            <Typography color={isSelected ? "primary.dark" : "primary"}>{returnString}</Typography>
        </IconButton>
    }
    if (!limit || limit <= 0)
        limit = 10
    const numPages = pagination && pagination.total ? (Math.floor(pagination.total / limit) + 1) : 0
    const selectedPage = pagination && pagination.offset ? Math.floor(pagination.offset / limit) : 0
    let pages = [...Array(numPages).keys()]
    if (pages.length > 5) {
        pages = [pages[0],
        ...[selectedPage - 1, selectedPage, selectedPage + 1].filter(itm => itm >= 0 && itm <= pages[pages.length - 1]),
        pages[pages.length - 1]]
        pages = [...new Set(pages)]
        pages = pages.sort((a, b) => a < b ? -1 : 1)
    }

    return (
        <div>
            <Box className={"container-horizontal content-start"} sx={{ mt: 2 }}>
                <Typography color={"primary"}>Showing {limit && pagination && pagination.total ? Math.min(limit, pagination.total) : 0} of {pagination && pagination.total ? pagination.total : 0}</Typography>
            </Box>
            <Box className={"container-horizontal content-center"} sx={{ mt: -4 }}>
                <IconButton key={"previousButton"} disabled={pagination && !pagination.hasPrevious} onClick={() => onPageNumberClick(selectedPage + 1 - 1)}>
                    {pagination && !pagination.hasPrevious ? <ArrowLeftGray /> : <ArrowLeftPink />}
                </IconButton>
                {pages.map((k, i) => pageButton(+k + 1, +k == selectedPage, i < pages.length - 1 ? +pages[i + 1] + 1 : -1))}
                <IconButton key={"nextButton"} disabled={pagination && !pagination.hasNext} onClick={() => onPageNumberClick(selectedPage + 1 + 1)}>
                    {pagination && !pagination.hasNext ? <ArrowRightGray /> : <ArrowRightPink />}
                </IconButton>
            </Box>
            <Box>
                <Typography color={"primary"} className={"container-horizontal content-end"} sx={{ mt: -4 }} display={"flex"} alignItems={"center"} gap={1}><ArrowDownFromLine size={20} /><p>Export</p></Typography>
            </Box>
        </div>

    )
}

export default PaginationData
