import React, {ReactNode} from "react";
import {AppBar, Box, Button, Container, IconButton, SvgIcon, Typography} from "@mui/material";
import {Language, Login, Logout} from '@mui/icons-material';
import {useAuthContext} from "@bytenite/auth/src/hoc/Auth/context";
import Documentation from "@bytenite/components/src/components/icons/Documentation";
import Avatar from "react-avatar";
import {useNavigate} from "react-router-dom";
// @ts-ignore
import {ReactComponent as LogoExtended} from '@bytenite/components/src/assets/bytenite-logo-horizontal-white.svg';
// @ts-ignore
import {ReactComponent as LogOutIcon} from "@bytenite/components/src/assets/logout-white.svg";

type LayoutPageProps = {
  compact?: boolean
  title?: string
  showLoginButton?: boolean
  showProfile?: boolean
  children: ReactNode
}
const LayoutPage: React.FC<LayoutPageProps> = ({title, showLoginButton, showProfile, children}) => {
  const auth = useAuthContext()
  const navigate = useNavigate()

  const isAuthenticated = auth.isAuthenticated()
  const userInfo = auth.getUserInfo()
  return <Container maxWidth={'xl'}>
    <AppBar sx={{minHeight: '50px'}} color="secondary">
      <Box display="flex" alignItems="center" my={1} mx={2}>
        <SvgIcon viewBox="" style={{minWidth: "136px", height: "48px"}}>
          <LogoExtended/>
        </SvgIcon>
        <Typography variant="body1">{title || ''}</Typography>
        <Box flexGrow={1}/>
        {showLoginButton &&
          (isAuthenticated ? <>
            <Button variant="text" color="inherit">Logout <Logout sx={{ml: 1}}/></Button>
          </> : <Button variant="text" color="inherit">Login <Login sx={{ml: 1}}/></Button>)
        }
        <IconButton title="Website" href="https://www.bytenite.com" target="_blank" color="inherit"><Language/></IconButton>
        <IconButton title="Documentation" href="https://docs.bytenite.com" target="_blank" color="inherit"><Documentation color="contrast"/></IconButton>
        {(showProfile && isAuthenticated) &&
            <IconButton sx={{ml:2}} onClick={() => navigate("/profile")}>
                <Avatar name={userInfo.displayName.substring(0, 1) || "user"} title={userInfo.displayName} size={"40"} round={true} color={!userInfo.displayName ? "lightgray" : ""}/>
            </IconButton>
        }
        {isAuthenticated &&
            <IconButton onClick={() => auth.signOut()} sx={{m: 2}}>
                <LogOutIcon></LogOutIcon>
            </IconButton>
        }
      </Box>
    </AppBar>
    <Box mt={9}>
      {children}
    </Box>
  </Container>
}

export default LayoutPage
