import {Alert, Box, BoxProps, IconButton, LinearProgress, Theme, Typography} from '@mui/material';

import React, {useEffect, useState} from 'react';
import {Close} from "@mui/icons-material";
import {ApiContextInterface} from "../hoc/ApiProvider/context";
import {EVENT_JOB_UPLOAD_COMPLETED, EVENT_JOB_UPLOAD_PROGRESS} from "../hoc/ApiProvider/Provider";
import {ClassNameMap, withStyles} from "@mui/styles";

export type FileUploadProgressProps = {
    box?: BoxProps
    jobId: string
    isCompleted: boolean
    uploadState: string
    api: ApiContextInterface
    classes?: ClassNameMap;
    onClose?: () => void;
}

const styles = (theme: Theme) => ({
    root: {

    },
    progress: {
        height: '6px',
        borderRadius: '6px'
    }
})

function FileUploadProgress({jobId, api, isCompleted, uploadState, box={}, classes, onClose, ...props}: FileUploadProgressProps) {
    const boxProps = {...box}
    const [uploadCompleted, setUploadCompleted]= useState(uploadState === 'completed')
    const [uploadFailed, setUploadFailed]= useState(uploadState === 'failed')
    const [progress, setProgress]= useState<number>(isCompleted ? 100: api.pendingUploadProgress(jobId))

    useEffect(() => {
        const cb = (e: CustomEvent) => {
            if (e.detail.id === jobId) {
                if (e.detail.success === false) {
                    setUploadFailed(true)
                } else {
                    setUploadCompleted(true)
                }
            }
        }
        const onProgress = (e: CustomEvent) => {
            if (e.detail.id === jobId) {
                const progress = e.detail.progress || {loaded: 0, total:1}
                setProgress((progress.loaded/progress.total) * 100)
            }
        }

        document.addEventListener(EVENT_JOB_UPLOAD_COMPLETED, cb)
        document.addEventListener(EVENT_JOB_UPLOAD_PROGRESS, onProgress)
        return () => {
            document.removeEventListener(EVENT_JOB_UPLOAD_COMPLETED, cb)
            document.removeEventListener(EVENT_JOB_UPLOAD_PROGRESS, onProgress)
        }
    }, [])

    if (uploadCompleted || uploadFailed) {
        return <Alert
          action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => onClose && onClose()}
              >
                  <Close fontSize="inherit" />
              </IconButton>
          }
          severity={uploadFailed ? "error" : "success"}>{uploadFailed ? "File upload error":"File uploaded"}</Alert>
    }

    return (
        <Box className="FileUploadProgress" {...boxProps} display="flex" flexDirection="row" alignItems="center">
            <Typography variant="subtitle2">File upload in progress...</Typography>
            <Box flexGrow="1" mx={2}>
                <LinearProgress className={classes.progress} variant="determinate" value={progress} />
            </Box>

        </Box>
    );
}

export default withStyles(styles)(FileUploadProgress)
