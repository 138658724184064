import React, {useState} from "react";
import {Box, Button, Typography} from "@mui/material";
import ChipSelect from "./formComponents/ChipSelect";
import ByteChip from "./icons/ByteChip";
import PriceCard, {PriceCardProps} from "./displayDataComponents/wallet/PriceCard";
import {Option, PricingPlan} from "../types/utils";
import {Refresh} from "@mui/icons-material";
import {addMonths} from 'date-fns';

type Variant = 'subscription'|'topup';
export interface CreateSubscriptionProps {
  currentPlan?: number
  plans: PricingPlan[]
  onConfirm: (value: number) => void;
  onChangePlanType?: (value: number) => void;
  variant?: Variant;
  subscriptionPlans?: PricingPlan[]
  isUpdate?: boolean
  nextBillingDate?: string
}

interface VariantProps {
  title: string
  cardTitle: string
  cardColor: PriceCardProps["color"]
}

const variantProps: {[key in Variant]: VariantProps} = {
  topup: {
    title: 'Select an amount',
    cardTitle: 'Stock',
    cardColor: 'yellow',
  },
  subscription: {
    title: 'Select a package',
    cardTitle: 'Subscription',
    cardColor: 'blue',
  },
}

const CreateSubscription: React.FC<CreateSubscriptionProps> = ({plans, currentPlan, onConfirm, onChangePlanType, variant='subscription', isUpdate=false, subscriptionPlans=[], nextBillingDate}) => {
  const defaultSelectedPackage = currentPlan ? plans.find(p => p.value === currentPlan) : undefined
  const [selectedPackage, setSelectedPackage] = useState<PricingPlan|undefined>(defaultSelectedPackage);
  const [isLoading, setIsLoading] = useState(false)
  const selectedVariant = variantProps[variant]

  const handleSelect = () => {
    if (selectedPackage?.value) {
      setIsLoading(true)
      onConfirm(selectedPackage?.value)
    }
  }

  const handleChangeMode = () => {
    if (selectedPackage?.value && onChangePlanType) {
      setIsLoading(true)
      onChangePlanType(selectedPackage?.value)
    }
  }

  const subscriptionSaving = selectedPackage ?  (selectedPackage.currencyAmount||0) - ((subscriptionPlans||[]).find(s => s.value === selectedPackage.value)?.currencyAmount||0) : 0
  nextBillingDate = nextBillingDate || addMonths(new Date(), 1).toDateString()


  return <Box>
    <Typography textAlign="center" variant="h3">{selectedVariant.title}</Typography>
    <Box mt={2}>
      <ChipSelect size="small" options={plans} value={selectedPackage?.value} onChange={(newVal) => setSelectedPackage(newVal)} suffixIcon={<ByteChip translateX="2px" translateY="4px" color="primary" size="xs"/>}/>
    </Box>
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" gap={4} mt={4}>
      {(selectedPackage && defaultSelectedPackage && selectedPackage?.value !== defaultSelectedPackage?.value && variant==='subscription') &&
          <PriceCard title="Current plan" color={selectedVariant.cardColor} tokenValue={defaultSelectedPackage.value} unitPrice={defaultSelectedPackage.unitPrice} dollarValue={defaultSelectedPackage.currencyAmount}
                                      isSubscription={true} dividerVariant="line" />
      }
      {(selectedPackage) && <PriceCard title={selectedVariant.cardTitle} color={selectedVariant.cardColor} tokenValue={selectedPackage.value} unitPrice={selectedPackage.unitPrice} dollarValue={selectedPackage.currencyAmount} subtitle={variant === 'subscription' ? 'recurring top-up' : 'one-off'}
                                     disabled={isLoading || (isUpdate ? selectedPackage?.value === currentPlan : false)} isSubscription={variant === 'subscription'}
                                     onSelect={handleSelect}/>}
    </Box>
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mt={1}>
      {(selectedPackage && subscriptionSaving && variant === 'topup' && onChangePlanType) && <Button sx={{mt:1}} variant="text" color="warning" onClick={() => handleChangeMode()} endIcon={<Refresh color="warning" sx={{transform: 'translateY(-1px)'}}/>}>Subscribe and save ${(subscriptionSaving/100).toFixed(2)} on this plan</Button>}
      {(variant === 'subscription' && selectedPackage) && <Typography mt={1} variant="subtitle1">Next billing date: {nextBillingDate}</Typography>}
    </Box>
  </Box>
}


export default CreateSubscription
