import React, {useEffect, useState} from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper, Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";
import { Add, Build, Delete, Edit, Info, PlayArrow } from "@mui/icons-material";
import DisplayDataTable from "@bytenite/components/src/components/displayDataComponents/_displayDataTable";
import {useAuthContext} from "@bytenite/auth/src/hoc/Auth/context";

type ConnectorsCallbackProps = {};
const ConnectorsCallback: React.FC<ConnectorsCallbackProps> = ({}) => {
  const auth = useAuthContext()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);

  const queryParams = new URLSearchParams(useLocation().search);
  const accessToken = queryParams.get('access_token')
  const expiry = queryParams.get('expiry')

  useEffect(() => {
    if (accessToken) {
      auth.setToken({accessToken: accessToken, expiresAt: parseInt(expiry), refreshToken: null}).then(() => {
        navigate('/convert/dropbox')
      }).catch(() => {
        //TODO: navigate to error page
      })
    }
  }, [accessToken]);


  return (
    <Box display="flex" flexDirection="column" alignItems="center" pt={4}>
      <Typography variant="h4" align="center" color="secondary.dark">
        Loading...
      </Typography>
      <Grid mt={2} spacing={2} justifyContent="center" container>
        <Grid item xs={12} sm={10} lg={8}>
          <Skeleton width="100%" height={200}/>
        </Grid>
        <Grid item xs={12} sm={10} lg={8}>
          <Skeleton width="100%" height={250}/>
        </Grid>
        <Grid item xs={12} sm={10} lg={8}>
          <Skeleton width="100%" height={150}/>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConnectorsCallback;
