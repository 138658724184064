import React from "react";
import {Box, Button, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {AddBoxOutlined, IndeterminateCheckBoxOutlined, Refresh} from "@mui/icons-material";

import {Bucket, Object as S3Object} from "aws-sdk/clients/s3";
import {ValidatorForm} from "react-material-ui-form-validator";

export interface StorjFileSelectionProps {
    selectedBucket?: string
    buckets: Bucket[]
    selectedBucketFiles: S3Object[]
    onBucketSelect: (bucketName?: string) => void
    onFileSelect: (bucketName?: string) => void
}



ValidatorForm.addValidationRule('storj', (value) => {
    return value.bucketName && value.name;
});

const StorjFileSelection = ({onBucketSelect, onFileSelect, buckets, selectedBucket, selectedBucketFiles}: StorjFileSelectionProps) => {

    return <Box display="flex" alignItems="end">
        <Grid spacing={1} container>
            <Grid xs={12} md={9} item>
                <Typography variant="subtitle1">Bucket selection</Typography>
                <Typography variant="subtitle2" color="textSecondary">Select the bucket where your file is stored</Typography>
            </Grid>
            <Grid xs={12} md={3} alignSelf="center" textAlign="right" item>
                <Button disabled={!selectedBucket} color="primary"><Refresh fontSize="small" onClick={() => onBucketSelect(selectedBucket)}/> Refresh</Button>
            </Grid>
            <Grid xs={12} item>
                <Table sx={{width: '100%'}} aria-label="buckets">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" sx={{maxWidth: '50px'}}></TableCell>
                            <TableCell>Bucket</TableCell>
                            <TableCell align="right">Creation date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {buckets.map((bucket) => (
                          <>
                              <TableRow key={bucket.Name}>
                                  <TableCell align="right" sx={{width: '32px'}}>
                                      <IconButton size="small" onClick={() => onBucketSelect(bucket.Name)}>
                                          {selectedBucket === bucket.Name ? <IndeterminateCheckBoxOutlined fontSize="medium" color="secondary"/> :
                                            <AddBoxOutlined fontSize="medium" color="secondary"/>}
                                      </IconButton>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                      {bucket.Name}
                                  </TableCell>
                                  <TableCell align="right">{bucket.CreationDate?.toLocaleString()}</TableCell>
                              </TableRow>
                              {selectedBucket === bucket.Name && <TableRow>
                                  <TableCell style={{paddingBottom: 0, paddingTop: 0}}/>
                                  <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={2}>
                                      <Box mx={0}>
                                          <Table sx={{width: '100%'}} aria-label="files">
                                              <TableHead>
                                                  <TableRow>
                                                      <TableCell align="left" sx={{width: '50px'}}></TableCell>
                                                      <TableCell>Name</TableCell>
                                                      <TableCell align="right">Size</TableCell>
                                                      <TableCell align="right">Last modified</TableCell>
                                                  </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                  {selectedBucketFiles.map((file) =>
                                                    <TableRow>
                                                        <TableCell align="left" sx={{width: '50px'}}>
                                                            <Button size="small" color="primary" onClick={() => onFileSelect(file.Key)}>Select file</Button>
                                                        </TableCell>
                                                        <TableCell>{file.Key}</TableCell>
                                                        <TableCell align="right">{file.Size}</TableCell>
                                                        <TableCell align="right">{file.LastModified?.toLocaleString()||'-'}</TableCell>
                                                    </TableRow>
                                                  )}
                                              </TableBody>
                                          </Table>
                                      </Box>
                                  </TableCell>
                              </TableRow>}
                          </>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    </Box>
}

export default StorjFileSelection
