import React from 'react';
import {Card, CardContent, Typography, Box} from '@mui/material';
import Circle, {CircleColor} from "../../icons/Circle";

export interface ByteChipsCardProps {
  title: string;
  amount: number;
  expiration?: Date;
  color: CircleColor;
}

const ByteChipsCard: React.FC<ByteChipsCardProps> = ({ title, expiration, amount, color }) => {
  return (
    <Card sx={{minWidth: '110px', minHeight: '140px'}}>
      <CardContent sx={{padding: '8px', paddingBottom: '4px!important'}}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h1" color="secondary.dark">
            {amount}
          </Typography>
          <Typography variant="subtitle1" component="h3" color="secondary.dark">
            ByteChips
          </Typography>
          <Box display="flex" flexDirection="row" alignItems="center" mt={1}>
            <Circle color={color} size="small" style={{marginRight: '5px'}}/>
            <Typography variant="body2">
              {title}
            </Typography>
          </Box>
          <Box>
            <Typography variant="caption" component="div" textAlign="center" color="primary.main">
              {expiration && `exp. ${expiration.toDateString()}`}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ByteChipsCard;
