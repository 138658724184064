import React from 'react';
import {
    Box,
    Button,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {Add} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import {Secret} from "./JobDataForm/types";

export type SecretSelectorProps = {
    value?: string;
    onSelect?: (id: string) => void
    secrets?: Secret[]
    onAddNew?: () => void
}

const SecretSelector = ({secrets = [], value, onSelect, onAddNew, ...props}: SecretSelectorProps) => {
    const theme = useTheme()
    const handleSelect = (id: string) => {
        if (onSelect) {
            onSelect(id)
        }
    }
    const handleAddNew = () => {
        if (onAddNew) {
            onAddNew()
        }
    }
    return <Box gap={2} pt={1} pb={3} px={2} sx={{background: theme.palette.grey.A100, borderRadius: '8px'}} display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle1">Credentials</Typography>
            <Button onClick={() => handleAddNew()} startIcon={<Add/>}>Add credentials</Button>
        </Box>
        <TableContainer>
            <Table aria-label="Ape keys" style={{width: '100%'}}>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Access key</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {secrets.map((row: Secret) => (
                        <TableRow key={row.id}>
                            <TableCell sx={{width: '20px', textAlign: 'center', px:0}}>
                                <Radio checked={value === row.id} onChange={(_, checked) => handleSelect(row.id)}/>
                            </TableCell>
                            <TableCell  sx={{width: '120px'}}>{row.name}</TableCell>
                            <TableCell>{row.accessKey}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

    </Box>
}

export default SecretSelector