import React from 'react';
import {AppBar as MuiAppBar, AppBarPropsColorOverrides, PropTypes} from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Avatar from 'react-avatar';
import {useState} from "react";
import {CSSObject, Theme, useTheme} from '@mui/material/styles';
import {ReactComponent as LogOutIcon} from "../../assets/logout-white.svg";
import {useNavigate} from "react-router-dom";
import {OverridableStringUnion} from "@mui/types";

export type AppBarProps = {
    color: OverridableStringUnion<"transparent" | PropTypes.Color, AppBarPropsColorOverrides>;
    sidebarOpen: boolean;
    logout: () => void;
    userFullName?: string;
}

const openedSidebar = (theme: Theme): CSSObject => ({
    width: `calc(100% - ${theme.sidebar?.width+"px"}`,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
});

const closedSidebar = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    width: `calc(100% - ${theme.sidebarClosed?.width+"px"}`,
});

export default function AppBar({...props} : AppBarProps) {

    const [isSearchOpen, setSearchOpen ] = useState(false);

    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <Box sx={{ flexGrow: 1, }}>
            <MuiAppBar color={props.color}
                       style={{
                           ...(props.sidebarOpen && {
                               ...openedSidebar(theme)
                           }),
                           ...(!props.sidebarOpen && {
                               ...closedSidebar(theme)
                           }),
                       }}
                       onClick={() =>
                           isSearchOpen &&
                           setSearchOpen(false)
                        }
            >
                <Toolbar>
                    {<>{/*{!isSearchOpen ?
                        <SearchIcon
                            sx={{ m: 1 }}
                            onClick={
                            () => setSearchOpen(true)
                        }/> :
                        <FormControl
                            sx={{ m: 1 }}
                            fullWidth>
                            <InputLabel className="subvariant-white">
                                Search
                            </InputLabel>
                            <OutlinedInput
                               //startAdornment={<SearchIcon></SearchIcon>}
                               label="Search"
                               color={"primary"}
                               autoComplete='off'
                               className="subvariant-white"
                               inputProps={ {
                                   className : "subvariant-white",
                               } }
                               placeholder="Type your search here"
                               onMouseEnter={() => setMouseOnSearch(true)}
                               onMouseLeave={() => setMouseOnSearch(false)}/>
                        </FormControl>}
                        {
                            notificationTurnedOn ?
                                <NotificationsIcon
                                    sx={{ m: 2 }}
                                    onClick={() => setNotificationTurnedOn(false)}
                                />:
                                <NotificationsNoneIcon
                                    sx={{ m: 2 }}
                                    onClick={() => setNotificationTurnedOn(true)}
                                />
                        }*/}
                        <IconButton
                            onClick={() => navigate("/profile")}
                        >
                            <Avatar name={props.userFullName || "user"} size={"40"}
                                    round={true} color={!props.userFullName ? "lightgray" : ""}/>
                        </IconButton>
                        <IconButton onClick={() => props.logout()}  sx={{ m: 2 }}>
                            <LogOutIcon></LogOutIcon>
                        </IconButton>
                    </>
                    }
                </Toolbar>
            </MuiAppBar>
        </Box>
    );
}
