import {BoxProps} from "@mui/material";
import {DataSourceDescriptor, JobDataSource} from "../../../types/JobDataSource";


export interface Secret {
  id: string;
  secretType?: string;
  name?: string;
  accessKey?: string
}

export interface JobDataFormProps extends Omit<BoxProps, 'onSubmit'> {
  onChange?: (formData: any) => any;
  onSubmit?: (formData: any, errors: any[]) => any; //
  onFileCancel?: (isUploading: boolean) => any;
  isFileUploading?: boolean;
  formData: JobDataSource
  secrets?: Secret[]
  onAddSecret?: (platform: string) => void
}

export const defaultSelectProps = {
  validators: ['required'],
  errorMessages: ['this field is required'],
  fullWidth: true,
}
export const defaultUrlProps = {
  validators: ['required', 'isUrl'],
  errorMessages: ['this field is required', 'Insert a valid url'],
  fullWidth: true,
}
export const _type_HttpDataSource = "type.googleapis.com/bytenite.data_source.HttpDataSource"
export const _type_S3DataSource = "type.googleapis.com/bytenite.data_source.S3DataSource"
export const _type_StorjDataSource = "type.googleapis.com/bytenite.data_source.StorjDataSource"
export const _type_LocalFileDataSource = "type.googleapis.com/bytenite.data_source.LocalFileDataSource"

export const _type_DropboxDataSource = "type.googleapis.com/bytenite.data_source.DropboxDataSource"
export const pbTypesMap: { [key in DataSourceDescriptor]: string } = {
  url: _type_HttpDataSource,
  storj: _type_StorjDataSource,
  gcp: _type_S3DataSource,
  s3: _type_S3DataSource,
  file: _type_LocalFileDataSource,
  dropbox: _type_DropboxDataSource,
  bucket: '',
  ftp: ''
}
