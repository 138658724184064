import * as React from 'react';
import {
    Table,
    TableHead,
    TableRow,
    TableBody, TableCell, Button, IconButton, LinearProgress, Box, Alert,
} from '@mui/material';
import NoDataIcon from "./NoDataIcon";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { ContentCopy, Download } from "@mui/icons-material";
import { useState } from "react";
import { ReactComponent as ArrowDownPurple } from "../../assets/arrow-down-purple.svg";
import { ReactComponent as ArrowUpPurpleDark } from "../../assets/arrow-up-purple-dark.svg";
import { ReactComponent as SortColumn } from "../../assets/sort-column.svg";
import CreatedChip from "../jobStates/CreatedChip";
import CompletedChip from "../jobStates/CompletedChip";
import ErrorChip from "../jobStates/ErrorChip";
import ProgressChip from "../jobStates/ProgressChip";
import UploadingChip from "../jobStates/UploadingChip";
import { Pagination } from "./PaginationData";

export interface DisplayDataTableProps {
    data: any[];
    columnsNames: string[];
    columnsOrder: string[];
    columnsSortable?: string[];
    iconNoData?: any;
    messageNoData?: string;
    displayTableIfNoData?: boolean;
    pagination?: Pagination;
    tableCellNoBorder?: boolean;
    transactionColumns?: string[];
    abortJob?: any;
    copyJob?: any;
    deleteJob?: any;
    downloadResults?: any;
    canAbort?: any;
    canEdit?: any;
    isComplete?: any;
    canDelete?: any;
    hasJobActions?: boolean;
    columnsJobState?: string[];
    onColumnClick?: any;
    columnsProgress?: string[]
    currentPageSize: number
}

function isValidHttpUrl(string: string | URL) {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

const DisplayDataTable = ({ ...props }: DisplayDataTableProps) => {

    const { data, currentPageSize, columnsNames, columnsOrder, iconNoData, messageNoData, displayTableIfNoData, tableCellNoBorder, transactionColumns, onColumnClick, columnsProgress, pagination } = props;
    const { abortJob, copyJob, canAbort, canEdit, isComplete, downloadResults, canDelete, deleteJob, hasJobActions, columnsJobState, columnsSortable } = props

    const [expandedRow, setExpandedRow] = useState('')

    const statePill = (state: string) => {
        switch (state) {
            case "NEW":
                return <CreatedChip></CreatedChip>
            case "COMPLETE":
                return <CompletedChip></CompletedChip>
            case "FAILED":
                return <ErrorChip></ErrorChip>
            case "UPLOADING":
                return <UploadingChip />
            default:
                return <ProgressChip></ProgressChip>
        }
    }

    const progressBar = (progress: number) => {
        return <Box sx={{ width: '200px' }}>
            <LinearProgress variant="determinate"
                color={"primary"}
                value={progress} />
        </Box>
    }

    const headerTable = <TableHead>
        <TableRow>
            {columnsOrder && columnsOrder.length > 0 ?
                columnsOrder.map(
                    (colName: any, index: number) =>
                    (
                        <TableCell
                            key={colName}
                            className={
                                index === 0 ? "table-header-left-cell" : (
                                    index === columnsNames.length - 1 ?
                                        "table-header-right-cell" : ""
                                )
                            }
                        >
                            {columnsSortable && columnsSortable.indexOf(colName) >= 0 ?
                                (<div className={"container-horizontal content-space-between"} style={{ cursor: "pointer" }} onClick={() => onColumnClick(colName)}>
                                    <div><Typography variant={"label2"}>{columnsNames[index]}</Typography></div><div style={{ marginLeft: "5px" }} className={"container-vertical content-center"}><SortColumn></SortColumn></div>
                                </div>) :
                                <Typography variant="label2">{columnsNames[index]}</Typography>
                            }

                        </TableCell>
                    )
                )
                :
                <TableCell>Missing column names</TableCell>}
        </TableRow>
    </TableHead>

    const filteredData = pagination ? data.filter((_, i) => (i >= pagination.offset) && (i < pagination.offset + currentPageSize)) : data


    return (
        <Box className={"container-vertical content-center-large"}>
            {
                data && data.length > 0 ?
                    <>
                        <Table className={"table-large"}>
                            {headerTable}
                            <TableBody>
                                {filteredData.map((row: any) => {
                                    const baseKey = row.id || (Object.keys(row).join('-') + data.indexOf(row))
                                    return <React.Fragment key={baseKey}><TableRow
                                        onClick={() => hasJobActions ? setExpandedRow(expandedRow == baseKey ? '' : baseKey) : ''}>
                                        {columnsOrder.map((k: any) => (
                                            Object.keys(row).indexOf(k) >= 0 ?
                                                <TableCell
                                                    style={tableCellNoBorder || (expandedRow == baseKey) ?
                                                        { borderBottom: "none", padding: "1em" } : { padding: "1em" }}
                                                    key={baseKey + k} align="left">

                                                    {isValidHttpUrl(row[k]) ?
                                                        (<a href={row[k]}>
                                                            {row[k].length >= 100 ? (row[k].substring(0, 100) + "...") : row[k]}
                                                        </a>) :
                                                        (columnsProgress && columnsProgress.indexOf(k) >= 0 ? progressBar(row[k]) :
                                                            (columnsJobState && columnsJobState.indexOf(k) >= 0 ? statePill(row[k]) :
                                                                <Typography color={transactionColumns && transactionColumns.indexOf(k) >= 0 ? (row[k] < 0 ? "error.main" : "success.main") : ""}>
                                                                    {row[k]}
                                                                </Typography>))}
                                                </TableCell> :
                                                (k == "expandIcon" ?
                                                    (<TableCell style={tableCellNoBorder || (expandedRow == baseKey) ?
                                                        { borderBottom: "none", padding: "1em" } : { padding: "1em" }}
                                                        key={baseKey + "expandIcon"}>
                                                        <IconButton onClick={
                                                            () => {
                                                                setExpandedRow(expandedRow == baseKey ? '' : baseKey)
                                                            }}>
                                                            {expandedRow == baseKey ? <ArrowUpPurpleDark></ArrowUpPurpleDark> : <ArrowDownPurple></ArrowDownPurple>}
                                                        </IconButton>
                                                    </TableCell>) : <></>)
                                        ))}
                                    </TableRow>
                                        {expandedRow == baseKey && hasJobActions == true && (
                                            <TableRow >
                                                {/* fill all the cells to make a row and then the last cell put the icons*/}
                                                {<TableCell align={"right"} colSpan={columnsOrder.length - 2}>
                                                    {row.errorMessage && <Alert sx={{ textAlign: 'left' }} color="error">{row.errorMessage}</Alert>}
                                                </TableCell>}
                                                {<TableCell align={"right"} colSpan={2}>
                                                    <IconButton size="small" color="secondary" sx={{ mx: 2 }} onClick={() => copyJob(row.id)}><ContentCopy fontSize="inherit" /></IconButton>
                                                    {canAbort && canAbort(row.state) ? <Button onClick={() => abortJob(row.id)} color="error" size="small">Abort</Button> : null}
                                                    {canEdit && canEdit(row.state) ? <Button component={Link} to={`/jobs/${row.id}`} color="primary" size="small">Edit</Button> : null}
                                                    {isComplete && isComplete(row.state) ? <IconButton sx={{ mx: 2 }} size="small" color="primary" onClick={() => downloadResults(row.id)}><Download fontSize="inherit" /></IconButton> : null}
                                                    {canDelete && canDelete(row.state) ? <Button onClick={() => deleteJob(row.id)} color="error" size="small">Delete</Button> : null}
                                                </TableCell>
                                                }
                                            </TableRow>
                                        )}</React.Fragment>
                                }
                                )}
                            </TableBody>
                        </Table>
                    </>
                    : <div className={"container-vertical content-center-large"}>
                        {displayTableIfNoData && <Table className={"table-large"}>
                            {headerTable}
                        </Table>}

                        {iconNoData && <NoDataIcon icon={iconNoData} message={messageNoData}></NoDataIcon>}
                    </div>
            }
        </Box>
    );
}

export default DisplayDataTable
