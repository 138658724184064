import {Box, Grid, MenuItem, Typography} from "@mui/material";
import React, {ForwardedRef, forwardRef, useCallback, useEffect, useState} from "react";
import {StorjFormData, StorjFormValidatorElement} from "../storj/StorjForm";
import {SelectValidator, TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import S3OutputForm from "../s3/S3OutputForm";
import FileInputForm from "../FileInputForm";
import {defaultSelectProps, defaultUrlProps, JobDataFormProps, pbTypesMap} from "./types";
import {DataSourceDescriptor, DataSourceParams, JobDataSource, S3DataSource} from "../../../types/JobDataSource";
import DropboxInputForm, {DropboxFormData} from "../dropbox/DropboxInputForm";

ValidatorForm.addValidationRule('isUrl', (value) => {
  try {
    new URL(value)
  } catch (e) {
    return false;
  }
  return true;
});

const JobDataForm = forwardRef(({onSubmit, onFileCancel, isFileUploading, formData, secrets=[], onAddSecret, ...props}: JobDataFormProps, ref: ForwardedRef<ValidatorForm>) => {
  const defaultForm: JobDataSource = {source: {dataSourceDescriptor: undefined, params: {}}, destination: {dataSourceDescriptor: 'bucket', params: {}}}
  const initialForm = {source: {...defaultForm.source,...(formData?.source||{})}, destination: {...defaultForm.destination,...(formData?.destination||{})}  }
  const [formState, setFormState] = useState<JobDataSource>(initialForm)
  const onSourceChanged = (value: DataSourceDescriptor) => {
    const newState: JobDataSource = {...formState}
    newState.source.dataSourceDescriptor = value
    newState.source.params = {'@type': pbTypesMap[newState.source.dataSourceDescriptor]}
    //newState.source.params['@type'] = pbTypesMap[newState.source.dataSourceDescriptor]
    setFormState(newState)
  }
  const onSourceParamsChanged = (value: any, key?: string) => {
    const newState = {...formState}
    if (key) {
      newState.source.params[key] = value
    } else {
      newState.source.params = value
    }
    setFormState(newState)
  }
  const onDestinationChanged = (value: DataSourceDescriptor) => {
    const newState: JobDataSource = {...formState}
    newState.destination.dataSourceDescriptor = value
    newState.destination.params = {'@type': pbTypesMap[newState.destination.dataSourceDescriptor]}
    //newState.destination.params['@type'] = pbTypesMap[newState.destination.dataSourceDescriptor]
    setFormState(newState)
  }
  const onDestinationParamsChanged = (value: object, key?: string) => {
    const newState: JobDataSource = {...formState}
    newState.destination.params = newState.destination.params||{}
    if (key) {
      newState.destination.params[key] = value
    } else {
      newState.destination.params = value as DataSourceParams
    }
    setFormState(newState)
  }

  const handleSubmit = useCallback((errors: any[]) => {
    if (onSubmit) {
      return onSubmit(formState, errors||[])
    }
  }, [formState, onSubmit])

  const handleAddSecret = (platform: string) => {
    if (onAddSecret) {
      onAddSecret(platform)
    }
  }

  useEffect(() => {
    ValidatorForm.addValidationRule('fileValidator', (value) => {
      return value?.name || isFileUploading || value?.uploaded
    });
    return () => {
      ValidatorForm.removeValidationRule('fileValidator')
    }
  }, [isFileUploading])

  return <Box {...props} display="flex" alignItems="end">
    <ValidatorForm
      style={{width: '100%'}}
      onSubmit={() => {handleSubmit([])}}
      onError={handleSubmit}
      ref={ref}
    >
      <Grid spacing={1} container>
        <Grid xs={12} item>
          <Typography color="secondary.dark" variant="h3">Source</Typography>
        </Grid>
        <Grid xs={12} sm={3} item>
          <SelectValidator {...defaultSelectProps} name="source" label="Data source" value={formState.source?.dataSourceDescriptor||''} onChange={(e: any) => {
            onSourceChanged(e.target.value as DataSourceDescriptor)
          }}>
            <MenuItem key="url" value="url">Url</MenuItem>
            <MenuItem key="storj" value="storj">Storj</MenuItem>
            <MenuItem key="file" value="file">Local file</MenuItem>
            <MenuItem key="dropbox" value="dropbox">Dropbox</MenuItem>
          </SelectValidator>
        </Grid>
        <Grid xs={12} sm={9} item>
          {formState.source.dataSourceDescriptor === 'url' &&
              <Box><TextValidator {...defaultUrlProps} name="source.url" label="Url" type="url" onChange={(e: any) => onSourceParamsChanged(e.target.value, 'url')}
                                  value={formState.source.params.url}/></Box>}
          {formState.source.dataSourceDescriptor === 'file' && <FileInputForm name="source.params" validators={['fileValidator']} errorMessages={['file not selected']} value={formState.source?.params} isUploading={isFileUploading||false} onChange={(formData:any) => {
            formData = formData||{}
            formData['@type'] = pbTypesMap.file
            onSourceParamsChanged(formData)
          }} onCancel={(isUploading: boolean) => {
            if (onFileCancel) {
              onFileCancel(isUploading)
            }
          } }/> }
          {formState.source.dataSourceDescriptor === 'storj' && <DropboxInputForm name="source.params" value={formState.source?.params} onChange={(formData: DropboxFormData) => {
            const formParams: Partial<DataSourceParams> = {...formData, '@type':  pbTypesMap.dropbox}
            onSourceParamsChanged(formParams)
          }} validators={['dropbox']} errorMessages={['Please select a file']}/>}
          {formState.source.dataSourceDescriptor === 'storj' && <StorjFormValidatorElement name="source.params" value={formState.source?.params} onChange={(formData: StorjFormData) => {
            const formParams: Partial<DataSourceParams> = {...formData, '@type':  pbTypesMap.storj}
            onSourceParamsChanged(formParams)
          }} validators={['storj']} errorMessages={['Please select a file']}/>}
        </Grid>

        <Grid xs={12} item>
          <Typography color="secondary.dark" variant="h3">Destination</Typography>
        </Grid>
        <Grid xs={12} sm={3} item>
          <SelectValidator {...defaultSelectProps} name="destination" label="Select destination" placeholder="Data destination" value={formState.destination?.dataSourceDescriptor||''}
                           onChange={(e: any) => {
                             onDestinationChanged(e.target.value)
                           }}>
            <MenuItem key="url" value="url">Url</MenuItem>
            <MenuItem key="storj" value="storj">Storj</MenuItem>
            <MenuItem key="gcp" value="gcp">GCP</MenuItem>
            <MenuItem key="bucket" value="bucket">Temporary bucket</MenuItem>
          </SelectValidator>
        </Grid>
        <Grid xs={12} sm={9} item>
          {formState.destination.dataSourceDescriptor === 'url' &&
              <Box><TextValidator {...defaultUrlProps} name="destination.url" label="Url" type="url" value={formState.destination?.params.url}
                                  onChange={(e: any) => onDestinationParamsChanged(e.target.value, 'url')}/></Box>}
          {formState.destination.dataSourceDescriptor === 'gcp' &&
              <Box><S3OutputForm prefix="destination" value={formState.destination.params as S3DataSource} cloudRegions={['us', 'eu', 'asia']} onChange={(formData, key) => {
                onDestinationParamsChanged(formData, key)
              }} secrets={secrets?.filter(s => s.secretType === 'gcp')} onAddSecret={() => handleAddSecret('gcp')}/></Box>}
          {formState.destination.dataSourceDescriptor === 'storj' &&
              <Box><S3OutputForm prefix="destination" value={formState.destination.params as S3DataSource} cloudRegions={['us1', 'eu1', 'ap1']} onChange={(formData, key) => {
                onDestinationParamsChanged(formData, key)
              }} secrets={secrets?.filter(s => s.secretType === 'storj')} onAddSecret={() => handleAddSecret('storj')}/></Box>}
        </Grid>
      </Grid>
    </ValidatorForm>
  </Box>
})

/* JobDataForm.defaultProps = {
  onChange: null,
  formData: {
    source: {dataSourceDescriptor: ''},
    destination: {dataSourceDescriptor: 'bucket'}
  }
}*/


export default JobDataForm
