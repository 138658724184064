import {
    Alert,
    Box,
    BoxProps,
    Button,
    IconButton,
    MenuItem,
    Select,
    SelectChangeEvent,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Theme,
    Typography
} from '@mui/material';

import React, {useEffect, useState} from 'react';
import {ContentCopy, Delete, KeyOff, Remove} from "@mui/icons-material";
import {AuthContextInterface, User} from "@bytenite/auth/src/hoc/Auth/context";
import ApiKeysTable from "./ApiKeysTable";
import OtpInput from "@bytenite/components/src/components/formComponents/otpInput/OtpInput";
import {AuthSignInResponse, DataSourceSecret} from "@bytenite/auth/src/client";
import {withStyles, WithStyles} from "@mui/styles";
import FormattedDate from "@bytenite/components/src/components/FormattedDate";
import {useModals} from "@bytenite/components/src/hoc/Modals/Provider";
import {FormChangeHandler, FormRenderer} from "@bytenite/components/src/hoc/Modals/context";
import EditSecretForm, {DataSourceSecretWithKey} from "./EditSecretForm";


export interface SecretsTableProps {
    box?: BoxProps
    auth: AuthContextInterface<User>
}

export interface AddSecretFormOptions {
    secretType?: string
}
export const AddSecretForm: FormRenderer<DataSourceSecretWithKey, AddSecretFormOptions> = (changeHandler, defaultValues, options?: AddSecretFormOptions) => {
    return <EditSecretForm changeHandler={changeHandler} defaultValues={defaultValues} secretType={options?.secretType}/>
}

function SecretsTable({box, auth, ...props}: SecretsTableProps) {
    const boxProps = {...box}
    const modals = useModals()

    const [isLoading, setIsLoading] = useState(true)
    const [secrets, setSecrets] = useState<DataSourceSecret[]>([])


    const loadSecrets = () => {
        auth.listSecrets().then(resp => {
            setSecrets(resp)
            setIsLoading(false)
        })
        /*auth.getApiKeys().then(resp => {
          setApiKeys(resp)
        })*/
    }

    const createSecret = () => {
        //modals.OkOnly("test", "test modal")
        modals.Form<DataSourceSecretWithKey, AddSecretFormOptions>("New connector", AddSecretForm, {size: 'sm', disableTypography: false}).then(async ({ok, data}) => {
            if (ok) {
                setIsLoading(true)
                const {secretKey, ...secret} = data
                await auth.saveSecret(secret, secretKey)
                loadSecrets()
            }
        }).catch((reason) => {
            if (!reason?.ok) {
                console.log('error', reason)
            }
        })
    }

    const handleDelete = (secret: DataSourceSecret) => {
        modals.YesNo("Confirm delete", `Are you sure you want to delete the ${secret.secretType} account: "${secret.name}"`).then(async (resp) => {
            if (resp.ok) {
                setIsLoading(true)
                await auth.deleteSecret(secret.secretType, secret.id)
                loadSecrets()
            }
        }).catch(e => {

        })
    }


    useEffect(() => {
        loadSecrets()
    }, [auth])

    return (
        <Box {...boxProps} className={"container-vertical content-center"}>
            <div className={"container-header-space-between"}>
                <Typography variant={"h3"} className={"color-blue1"} style={{lineHeight: 2}}>
                    External connectors
                </Typography>
                <Button variant="text" color="primary" disabled={isLoading} onClick={() => createSecret()}>New connector</Button>
            </div>
            <div className={"container-vertical content-start"}>
                <Box className="DataSource" pb={3}>
                    <TableContainer>
                        <Table aria-label="Ape keys" style={{width: '100%'}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Platform</TableCell>
                                    <TableCell>Id</TableCell>
                                    <TableCell>Expiration</TableCell>
                                    <TableCell>Access key</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {secrets.map((row: DataSourceSecret) => (
                                    <TableRow key={row.id}>
                                        <TableCell component="th" scope="row">{row.name}</TableCell>
                                        <TableCell>{row.secretType}</TableCell>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.expiresAt &&
                                            <FormattedDate dateString={new Date(row.expiresAt)?.toDateString()}/>}</TableCell>
                                        <TableCell>{row.accessKey}</TableCell>
                                        <TableCell sx={{minWidth: '150px'}}>
                                            <Button variant="text" size="small" color="error" disabled={isLoading}
                                                    onClick={() => handleDelete(row)}>
                                                <Delete fontSize="small"/>
                                                <Typography mx={1} variant={"subtitle2"}>delete</Typography>
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </div>
        </Box>
    );
}

// @ts-ignore
export default SecretsTable
