import React from 'react';


const colorMap = {
  yellow: '#FECB00',
  blue: '#3638C4',
}
const sizeMap = {
  small: '8px',
  medium: '16px',
  large: '24px',
}

export type CircleColor = keyof typeof colorMap;
interface SvgIconProps {
  color: CircleColor;
  size: keyof typeof sizeMap;
  style?:  React.CSSProperties;
}

const Circle: React.FC<SvgIconProps> = React.memo(({ color, size, style }) => {
  return (
    <span style={{width: sizeMap[size], height: sizeMap[size], background: colorMap[color], borderRadius: 9999, ...style}} />
  );
});

export default Circle;
