import {IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@mui/material';

import React from 'react';
import {Delete} from "@mui/icons-material";

export interface FileItem {
    id?: number|string
    filename?: string
    path?: string
    size?: number|string
}

export type FileTableProps = {
    files: FileItem[]
    isOutput?: boolean
    onRemove?: (file: FileItem) => void;
}

export const getReadableFileSizeString = (fileSizeInBytes: number,) => {
    let i = -1;
    const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
        fileSizeInBytes /= 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

function FileTable({files, onRemove, isOutput=false, ...props}: FileTableProps) {
    return (
      <Table sx={{width: "100%"}}>
          <TableHead>
              <TableRow>
                  <TableCell>
                      <Typography variant="label2">Path</Typography>
                  </TableCell>
                  <TableCell>
                      <Typography variant="label2">File name</Typography>
                  </TableCell>
                  {!isOutput ? <TableCell width={150}> <Typography variant="label2">Size</Typography></TableCell> : null}
                  <TableCell width={50}></TableCell>
              </TableRow>
          </TableHead>
          <TableBody>
              {files.map((file, i) =>
                <TableRow key={i}>
                    <TableCell>{file?.path||'/'}</TableCell>
                    <TableCell>{file?.filename}</TableCell>
                    {!isOutput ? <TableCell>{file?.size ? getReadableFileSizeString(+file?.size) : ''}</TableCell> : null}
                    <TableCell align="right">
                        {onRemove && <IconButton onClick={() => onRemove(file)} sx={{my: 0.5}} size="small" color="error">
                            <Delete/>
                        </IconButton>}
                    </TableCell>
                </TableRow>
              )}

          </TableBody>
      </Table>
    );
}

export default FileTable
