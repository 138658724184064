import React, {useState} from 'react';
import {FormChangeHandler} from "@bytenite/components/src/hoc/Modals/context";
import {DataSourceSecret} from "@bytenite/auth/src/client";
import {Box, Divider, MenuItem, Select, Stack, TextField, Typography} from "@mui/material";

export interface DataSourceSecretWithKey extends DataSourceSecret {
    secretKey: string
}

export type EditSecretFormFormProps = {
    changeHandler: FormChangeHandler<DataSourceSecretWithKey>
    defaultValues: Partial<DataSourceSecretWithKey>
    secretType?: string
}

const EditSecretForm = ({changeHandler, defaultValues, secretType, ...props}: EditSecretFormFormProps) => {
    const [formData, setFormData] = useState<DataSourceSecretWithKey>({
        accessKey: "",
        secretKey: "",
        expiresAt: undefined,
        id: "",
        name: "",
        params: {},
        secretType: secretType|| "",
        ...defaultValues
    })

    const setFormField = <K extends keyof DataSourceSecretWithKey>(field: K, value: DataSourceSecretWithKey[K]) => {
        setFormData({ ...formData, [field]: value });
        changeHandler(field, value);
    };

    return <Box gap={2} display="flex" flexDirection="column" py={2}>
        <Typography variant="subtitle2">Connector info</Typography>
        <Select value={formData.secretType||''} disabled={!!secretType} onChange={(e) => setFormField("secretType",e.target.value)} label="Platform">
            <MenuItem value="storj">Storj</MenuItem>
            <MenuItem value="gcp">GCP</MenuItem>
            <MenuItem value="s3">S3</MenuItem>
        </Select>
        <TextField label="Name" value={formData.name||''} onChange={(e) => setFormField("name",e.target.value)} fullWidth/>
        <Divider/>
        <Typography variant="subtitle2">Credentials</Typography>
        <TextField value={formData.accessKey} onChange={(e) => setFormField("accessKey",e.target.value)} label="Access key" fullWidth/>
        <TextField value={formData.secretKey} onChange={(e) => setFormField("secretKey",e.target.value)} label="Secret key" fullWidth/>
    </Box>
}

export default EditSecretForm