import React from 'react';
import {Card, CardContent, Typography, Button, Box, Divider} from '@mui/material';
import {ReactComponent as ArrowRight} from "../../../assets/jobIcons/arrow-right-white.svg";
import ArrowsUpDown from "../../icons/ArrowsUpDown";
import Circle, {CircleColor} from "../../icons/Circle";
import ByteChip from "../../icons/ByteChip";

export interface PriceCardProps {
  title: string;
  subtitle?: string;
  tokenValue: number;
  dollarValue: number;
  unitPrice: number;
  onSelect?: () => void;
  color: CircleColor;
  disabled?: boolean
  isSubscription?: boolean
  dividerVariant?: 'arrows'|'line'
}

const PriceCard: React.FC<PriceCardProps> = ({ title, subtitle, tokenValue, dollarValue, unitPrice, onSelect, color, disabled,isSubscription, dividerVariant='arrows' }) => {
  return (
    <Card sx={{maxWidth: '283px'}}>
      <CardContent>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box display="flex" flexDirection="row" alignItems="center">
            <Circle color={color} size="small" style={{marginRight: '5px'}}/>
            <Typography variant="h5" component="h2">
              {title}
            </Typography>
          </Box>
          {subtitle && <Typography variant="subtitle1" component="h3" sx={{}}>
            {subtitle}
          </Typography>}
          <Box display="flex" flexDirection="row" alignItems="center">
            <div><ByteChip color="secondary" colorVariant="dark"/></div>
            <Typography variant="h1" color="secondary.dark">{tokenValue}</Typography>
          </Box>
          <Box my={1}>
            {dividerVariant === 'arrows' ? <ArrowsUpDown color="secondary" colorVariant="dark"/> : <Divider sx={{width:'160px'}}/>}
          </Box>
          <Typography variant="h1" color="secondary.dark">
            $ {(dollarValue/100).toFixed(0)}{isSubscription ? '/mo' : ''}
          </Typography>
          <Typography variant="body2" color="grey.200">
            ${(unitPrice/100).toFixed(4)}/ByteChip
          </Typography>
          {onSelect && <Box mt={4}>
              <Button variant="contained" color="primary" onClick={onSelect} disabled={disabled}>
                  Select
                  <ArrowRight style={{marginLeft: '10px'}} color="white"/>
              </Button>
          </Box>}
        </Box>
      </CardContent>
    </Card>
  );
};

export default PriceCard;
