import React, { useContext } from 'react';
import AuthContext from "../Auth/context";

interface PrivateRouteProps {
    component: React.JSXElementConstructor<any>
}

const PrivateRoute = <T extends React.PropsWithChildren>({component: Component, ...rest}: PrivateRouteProps) => {

    const authCtx = useContext(AuthContext)
    const isAuthenticated = authCtx.isAuthenticated()

    if (!isAuthenticated) {
        localStorage.setItem('redirect-count', '1')

        authCtx.getLoginUrl().then(loginUrl => {
            window.location.href = loginUrl
        })
        return <></>
    }
    //<Navigate replace to={''} state={{ from: location.pathname }} />

    return isAuthenticated ? <Component {...rest} userInfo={authCtx.getUserInfo} /> : <></>;
};

export default PrivateRoute;
