import React from "react";

type dateTimeStyle = "medium" | "full" | "long" | "short"
interface Props {
  dateString: string;
  locale?: string;
  dateStyle?: dateTimeStyle;
  timeStyle?: dateTimeStyle;
}

function FormattedDate({dateString, locale, dateStyle="medium", timeStyle="medium"}: Props) {
  const date = new Date(dateString);
  const formattedDate = new Intl.DateTimeFormat(locale||navigator.language, {
    dateStyle: dateStyle,
    timeStyle: timeStyle,
  }).format(date);

  return <span>{formattedDate}</span>;
}

export default FormattedDate;
