import {Alert, alpha, Box, BoxProps, Collapse, Typography} from "@mui/material";
import React from "react";
import {animated, useSpring} from '@react-spring/web';
import {ValidatorComponent, ValidatorComponentProps, ValidatorForm} from "react-material-ui-form-validator";
import {Error, Folder, FolderOpen, RadioButtonChecked, RadioButtonUnchecked} from "@mui/icons-material";
import {TreeItem, treeItemClasses, TreeItemProps, TreeView} from "@mui/x-tree-view";
import {styled} from "@mui/material/styles";
import {TransitionProps} from "@mui/material/transitions";
import FileExplorer, {Directory} from "../FileExplorer";

export interface DropboxFormData {
    id: string
    name: string
    temp_url?: string
}

export interface DropboxInputFormProps extends BoxProps {
    rootDirectory?: Directory;
    onChange?: (formData: any) => any;
    onCancel?: (isUploading: boolean) => any;
    disabled?: boolean;
    allowedExtensions?: string[]
    value: {
        name?: string
    }
}

function TransitionComponent(props: TransitionProps) {
    const style = useSpring({
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

const CustomTreeItem = React.forwardRef(
    (props: TreeItemProps, ref: React.Ref<HTMLLIElement>) => (
        <TreeItem {...props} TransitionComponent={TransitionComponent} ref={ref}/>
    ),
);

const StyledTreeItem = styled(CustomTreeItem)(({theme}) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        '& .close': {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}));

ValidatorForm.addValidationRule('dropbox', (value) => {
    return value.id && value.name;
});
const getReadableFileSizeString = (fileSizeInBytes: number,) => {
    let i = -1;
    const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
        fileSizeInBytes /= 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

export class DropboxInputForm extends ValidatorComponent {
    props: ValidatorComponentProps & DropboxInputFormProps

    constructor(props: ValidatorComponentProps & DropboxInputFormProps) {
        super(props);
        this.props = props;
    }

    renderValidatorComponent() {
        const {value, errorMessages, validatorListener, onChange, rootDirectory, allowedExtensions, ...rest} = this.props;
        /*if (value?.name) {
          return <>
            <Box {...rest} display="flex" alignItems="center" flexDirection="row">
              <Typography variant="subtitle1">{value.name}</Typography>
              <Box mx={1}><Typography variant="subtitle2" color="textSecondary">({getReadableFileSizeString(value.size)})</Typography></Box>
              <Box>
                <IconButton onClick={() => {
                  this.onFileChange(null)
                }} size="small" color="error"><Cancel/></IconButton>
              </Box>
            </Box>
            {this.errorText()}
          </>
        }*/
        if (!value?.path && !rootDirectory?.files && !rootDirectory?.directories) {
            return <Alert color="warning">No files available</Alert>
        }
        const disabled = this.props.disabled || false
        return (
            <>
                <Box {...rest} sx={{minHeight: 160, flexGrow: 1, maxHeight: 480, overflowY: 'auto', overflowX: 'hidden'}} display="flex" alignItems="start">
                    <FileExplorer rootDirectory={rootDirectory} selectedPath={value?.path||'/'} onChange={(f) => onChange && onChange(f) } allowedExtensions={allowedExtensions} />
                </Box>
                {this.errorText()}
            </>
        );
    }

    onFileChange(e: any) {
        const file = e && e.target.files && e.target.files.length > 0 ? e.target.files[0] : null
        if (this.props.onChange) {
            this.props.onChange(file)
        }
    }

    onCancel(isUploading: boolean) {
        if (this.props.onCancel) {
            this.props.onCancel(isUploading)
        }
    }

    getErrorMessage() {
        return "this field is required"
    }

    errorText() {
        const isValid = this.isValid()
        if (isValid) {
            return null;
        }

        return (
            <Box mt={2}>
                <Typography variant="subtitle2" color="error"><Error color="error" fontSize="small" sx={{transform: 'translateY(2px)'}}/> {this.getErrorMessage()}</Typography>
            </Box>
        );
    }
}


export default DropboxInputForm
