import {BoxProps, Button, Grid, TextField, Typography} from "@mui/material";
import React, {useCallback, useState} from "react";
import {Error, Lock} from "@mui/icons-material";
import StorjClient from "./client";

export interface VideoDimensionsProps {
    onSubmit?: (client: StorjClient) => any;
    accessKey?: string;
    secretKey?: string;
    submitText?: string;
}

interface FormData {
    accessKeyId: string;
    secretAccessKey: string;
}

type FormField = 'accessKeyId'|'secretAccessKey';

interface FormEventTarget {
    value: string;
    name: string|FormField;
}
interface FormEvent {
    target: EventTarget & FormEventTarget;
}

const StorjCredentials = ({onSubmit, submitText='Next'}: VideoDimensionsProps & Omit<BoxProps, 'onSubmit'>) => {
    //const storjClient = new Storj()
    const [formData, setFormData] = useState<FormData>({accessKeyId: '', secretAccessKey: ''})
    const [error, setError] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const checkCredentials = useCallback(() => {
        const credentials = {...formData}
        setIsLoading(true)

        const client = new StorjClient(credentials.accessKeyId, credentials.secretAccessKey)
        client.listBuckets().then(() => {
            if (onSubmit) {
                onSubmit(client)
            }
        }).catch(err => {
            setError(err.message)
        }).finally(() => {
            setIsLoading(false)
        })
    }, [formData, onSubmit])

    const onChange = useCallback((e: FormEvent) => {
        //(e) => setAccessKey(e.target.value)
        setError('')
        const newFormData = {...formData}
        newFormData[e.target.name as FormField] = e.target.value
        setFormData(newFormData)

    }, [formData])

    return <Grid spacing={2} container>
        <Grid item xs={12}>
            <Typography variant="subtitle1">Please enter your Storj credentials</Typography>
            <Typography variant="subtitle2" color="textSecondary"><Lock fontSize="small" sx={{transform: 'translateY(3px)'}}/> your credentials won't be sent to our servers</Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <TextField disabled={isLoading} error={!!error} label="access key" name="accessKeyId" defaultValue={formData.accessKeyId} onChange={onChange} fullWidth></TextField>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
            <TextField disabled={isLoading} error={!!error} label="secret key" name="secretAccessKey" defaultValue={formData.secretAccessKey} onChange={onChange} type="password" fullWidth></TextField>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
            <Button disabled={!formData.accessKeyId||!formData.secretAccessKey||!!error||isLoading} onClick={checkCredentials} variant="contained">{submitText}</Button>
        </Grid>
        {!!error && <Grid item xs={12}>
            <Typography variant="subtitle2" color="error"><Error color="error" fontSize="small" sx={{transform: 'translateY(2px)'}}/> {error}</Typography>
        </Grid>}
    </Grid>
}

export default StorjCredentials
