import {alpha, Collapse} from '@mui/material';

import React from 'react';
import {Check, Folder, FolderOpen, RadioButtonChecked, RadioButtonUnchecked} from "@mui/icons-material";
import {TreeItem, treeItemClasses, TreeItemProps, TreeView} from "@mui/x-tree-view";
import {TransitionProps} from "@mui/material/transitions";
import {animated, useSpring} from "@react-spring/web";
import {styled} from "@mui/material/styles";


export interface Directory {
  name?: string;
  path?: string;
  directories?: Array<Directory>;
  files?: Array<File>;
}

export interface File {
  id?: string;
  name?: string;
  path?: string;
  size?: string;
}

export type FileExplorerProps = {
  selectedPath?: string
  rootDirectory?: Directory
  onChange?: (selectedItem: File | Directory) => void
  disabled?: boolean
  allowedExtensions?: string[]
  selectDirectory?: boolean
}

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (<animated.div style={style}>
    <Collapse {...props} />
  </animated.div>);
}

const CustomTreeItem = React.forwardRef((props: TreeItemProps, ref: React.Ref<HTMLLIElement>) => (<TreeItem {...props} TransitionComponent={TransitionComponent} ref={ref}/>),);

const StyledTreeItem = styled(CustomTreeItem)(({theme}) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  }, [`& .${treeItemClasses.group}`]: {
    marginLeft: 15, paddingLeft: 18, borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));
export default function FileExplorer({selectedPath, rootDirectory, onChange, allowedExtensions, disabled = false, selectDirectory = false}: FileExplorerProps) {
  const getDirectory = (filePath: string) => {
    const spl = filePath.split("/")
    return spl.filter((_, i) => i < spl.length - 1).join('/')
  }

  const getSubDirectories = (filePath: string) => {
    const spl = filePath.split("/")
    return spl.filter((_, i) => i < spl.length - 1).map((v, i) => i === 0 ? v : [...spl.slice(0, i), v].join("/"))
  }

  const getFileExtension = (fileName: string) => {
    const spl = fileName.split(".")
    return spl[spl.length - 1]
  }

  const handleSelectDirectory = (dir: Directory) => {
    if (!selectDirectory || !onChange) {
      return
    }
    onChange(dir)
  }

  const mapDirectory = (dir?: Directory, selectedFilePath?: string) => {
    if (!dir) {
      return ''
    }
    return <>
      {!selectDirectory && (dir?.files || []).filter(f => !allowedExtensions || allowedExtensions.indexOf(getFileExtension(f.name || '')) !== -1).map((f, i) => (
        <StyledTreeItem disabled={disabled} key={`file-${i}`} nodeId={f.path || `file-${i}`}
                        onClick={() => onChange && onChange({...f})}
                        endIcon={f.path && f.path === selectedFilePath ? <RadioButtonChecked color={disabled ? "disabled" : "secondary"}/> :
                          <RadioButtonUnchecked color={disabled ? "disabled" : "secondary"}/>}
                        label={f.name}/>))}
      {(dir.directories || []).map((d, i) => <StyledTreeItem key={`file-${i}`} onClick={() => handleSelectDirectory(d)}  disabled={disabled} nodeId={d.path || `dir-${i}`} label={<>{selectDirectory && d.path === selectedFilePath && <Check sx={{transform: 'translateY(2px)'}} color="success" fontSize="small"/>} {d.name}</>}>
        {mapDirectory(d, selectedFilePath)}
      </StyledTreeItem>)}
    </>
  }
  const emptyRootDirectory: Directory = {directories: [], files: [], name: "/", path: "/"}
  return <TreeView
    defaultCollapseIcon={<FolderOpen color={disabled ? "disabled" : "primary"}/>}
    defaultExpandIcon={<Folder color={disabled ? "disabled" : "primary"}/>}
    defaultEndIcon={<RadioButtonUnchecked color={disabled ? "disabled" : "secondary"}/>}
    defaultExpanded={['/', ...getSubDirectories(selectedPath || '')]}
    sx={{overflowX: 'hidden'}}
  >
    <StyledTreeItem onClick={() => handleSelectDirectory(rootDirectory||emptyRootDirectory)} disabled={disabled} nodeId={rootDirectory?.path || '/'} label={<>{selectDirectory && rootDirectory?.path === selectedPath && <Check sx={{transform: 'translateY(2px)'}} color="success" fontSize="small"/>} /</>}>
      {mapDirectory(rootDirectory, selectedPath)}
    </StyledTreeItem>

  </TreeView>
}
