import S3, {ListBucketsOutput, ListObjectsV2Output, ListObjectsV2Request} from "aws-sdk/clients/s3";


const defaultEndpoint = "https://gateway.storjshare.io"
export default class StorjClient {
  private readonly _endpoint: string;
  private readonly _secretAccessKey: string;
  private readonly _accessKeyId: string;
  private _s3: S3;
  static s3Config(accessKeyId: string, secretAccessKey: string, endpoint: string) {
    return {
      accessKeyId,
      secretAccessKey,
      endpoint,
      s3ForcePathStyle: true,
      signatureVersion: "v4",
      connectTimeout: 0,
      httpOptions: { timeout: 0 }
    }
  }

  constructor(accessKeyId: string, secretAccessKey: string, endpoint?: string) {
    this._accessKeyId = accessKeyId;
    this._secretAccessKey = secretAccessKey;
    this._endpoint = endpoint||defaultEndpoint;
    this._s3 = new S3(StorjClient.s3Config(accessKeyId, secretAccessKey, this._endpoint))
  }

  async listBuckets(): Promise<ListBucketsOutput> {
    return new Promise((resolve, reject) => {
      this._s3.listBuckets((err, data) => {
        if (err) {
          reject(err)
        } else {
          resolve(data)
        }
      })
    })
  }
  getSignedURL(bucketName:string, fileName:string): string {
    const params = ({
      Bucket: bucketName,
      Key: fileName,
      Expires: 60 * 60
    })
    return this._s3.getSignedUrl('getObject', params)
  }
  async listFiles(bucket: string): Promise<ListObjectsV2Output> {
    const params: ListObjectsV2Request = {
      Bucket: bucket
    }
    return new Promise((resolve, reject) => {
      this._s3.listObjectsV2(params,(err, data) => {
        if (err) {
          reject(err)
        } else {
          resolve(data)
        }
      })
    })
  }

}
