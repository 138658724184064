import React from 'react';
import {useTheme} from "@mui/material/styles";

type IconSize = 'xxs'|'xs'|'small'|'medium'|'large'

export interface SvgIconProps {
  color?: 'primary'|'secondary'|'error'|'warning'|'info'|'success'|'textPrimary'|'contrast'|'disabled'|'inherit';
  size?: IconSize
  colorVariant?: 'main'|'light'|'dark';
  style?:  React.CSSProperties;
  viewBox?:  string;
  translateY?:  string;
  translateX?:  string;
  path: string;
}

const sizes: {[key in IconSize]: string} = {
  xxs: '12',
  xs: '15',
  small: '20',
  medium: '30',
  large: '40'
}

const Icon: React.FC<SvgIconProps> = React.memo(({ color, colorVariant='main',size='medium', style, path, viewBox, translateX='0', translateY='0' }) => {
  const theme = useTheme()
  let paletteColor = ''
  switch (color){
    case 'inherit':
      paletteColor = 'inherit'
      break
    case 'textPrimary':
      paletteColor = theme.palette.text.primary
      break
    case 'contrast':
      paletteColor = theme.palette.primary.contrastText
      break
    case 'disabled':
      paletteColor = theme.palette.text.disabled
      break
    default:
      paletteColor = color ? theme.palette[color][colorVariant] : theme.palette.text.primary
  }

  const svgStyle = {...style}

  if (translateY) {
    svgStyle['transform'] = `translate(${translateX},${translateY})`
  }

  const sizeValue = sizes[size]
  return (
    <svg width={sizeValue} height={sizeValue} viewBox={viewBox||'0 0 20 20'} fill="none" xmlns="http://www.w3.org/2000/svg" style={svgStyle}>
      <path d={path}  fill={paletteColor} stroke={paletteColor} className="icon-path"  strokeWidth="0.3"/>
    </svg>
  );
});

export default Icon;
