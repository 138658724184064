import React, {ReactNode, JSX} from 'react';
import {Box, Typography} from '@mui/material';

import {ReactComponent as FacebookIcon} from "@bytenite/components/src/assets/socialIcons/facebook-icon-white.svg";
import {ReactComponent as TwitterIcon} from "@bytenite/components/src/assets/socialIcons/twitter-icon-white.svg";
import {ReactComponent as LinkedinIcon} from "@bytenite/components/src/assets/socialIcons/linkedin-icon-white.svg";
import {ReactComponent as InstagramIcon} from "@bytenite/components/src/assets/socialIcons/instagram-icon-white.svg";
import {ReactComponent as YouTubeIcon} from "@bytenite/components/src/assets/socialIcons/youtube-icon-white.svg";

interface LayoutProps {
  children: JSX.Element|JSX.Element[];
  title?: React.ReactNode;
  width?: string;
  renderTitle?: () => React.ReactNode;
}

interface SocialMediaLinkProps {
  href: string;
  icon: React.ReactNode;
}

const SocialMediaLink: React.FC<SocialMediaLinkProps> = ({href, icon}) => (
  <Box m={2}>
    <a href={href} target="_blank">
      {icon}
    </a>
  </Box>
);

const Layout: React.FC<LayoutProps> = ({children, title, width = '30%', renderTitle}) => {
  return (
    <div className="signin-page container-vertical content-space-between">
      <Box my={5} mx={3}>
        <div className="container-horizontal content-start">
          {renderTitle ? (
            renderTitle()
          ) : (
            <Typography color="white" variant="h2">
              {title}
            </Typography>
          )}
        </div>
      </Box>
      <Box>
        <div className="container-horizontal content-center">
          <Box width={width}>
            <div className="container-vertical content-start">{children}</div>
          </Box>
        </div>
      </Box>

      <Box mt={20} mb={5}>
        <div className="container-horizontal content-center">
          <SocialMediaLink href="https://www.linkedin.com/company/bytenite/" icon={<LinkedinIcon/>}/>
          <SocialMediaLink href="https://www.facebook.com/bytenite2" icon={<FacebookIcon/>}/>
          <SocialMediaLink href="https://www.instagram.com/bytenite/" icon={<InstagramIcon/>}/>
          <SocialMediaLink href="https://twitter.com/bytenite2" icon={<TwitterIcon/>}/>
          <SocialMediaLink href="https://www.youtube.com/@bytenite6071" icon={<YouTubeIcon/>}/>
        </div>
      </Box>
    </div>
  );
};
export default Layout;
