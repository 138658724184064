import React from 'react';
import {useTheme} from "@mui/material/styles";
import Icon, {SvgIconProps} from "./Icon";


const RedeemIcon: React.FC<Omit<SvgIconProps, 'path'>> = React.memo((props) => {
  return <Icon translateY="-1px" viewBox="0 0 12 13" path="M4.45312 2.11719C4.28906 1.8125 3.96094 1.625 3.60938 1.625H3.5625C3.02344 1.625 2.625 2.04688 2.625 2.5625C2.625 3.10156 3.02344 3.5 3.5625 3.5H5.25H5.27344L4.45312 2.11719ZM1.5 2.5625C1.5 1.4375 2.41406 0.5 3.5625 0.5H3.60938C4.35938 0.5 5.03906 0.898438 5.41406 1.55469L6 2.51562L6.5625 1.55469C6.9375 0.898438 7.61719 0.5 8.36719 0.5H8.4375C9.5625 0.5 10.5 1.4375 10.5 2.5625C10.5 2.91406 10.4062 3.21875 10.2656 3.5H11.25C11.6484 3.5 12 3.85156 12 4.25V5.75C12 6.17188 11.6484 6.5 11.25 6.5H0.75C0.328125 6.5 0 6.17188 0 5.75V4.25C0 3.85156 0.328125 3.5 0.75 3.5H1.71094C1.57031 3.21875 1.5 2.91406 1.5 2.5625ZM9.375 2.5625C9.375 2.04688 8.95312 1.625 8.4375 1.625H8.36719C8.01562 1.625 7.71094 1.8125 7.52344 2.11719L6.70312 3.5H6.75H8.4375C8.95312 3.5 9.375 3.10156 9.375 2.5625ZM0.75 7.25H5.25V12.5H1.875C1.24219 12.5 0.75 12.0078 0.75 11.375V7.25ZM6.75 12.5V7.25H11.25V11.375C11.25 12.0078 10.7344 12.5 10.125 12.5H6.75Z"
               {...props}/>
});

export default RedeemIcon;
