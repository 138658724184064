import React from 'react';
import {Link, Link as RouterLink} from 'react-router-dom';
import {ListItem, ListItemBaseProps, ListItemIcon, ListItemText, Theme} from '@mui/material';
import {makeStyles, createStyles} from "@mui/styles";
import {ListItemClasses} from "@mui/material/ListItem/listItemClasses";
import clsx from "clsx";
import Typography, {ColorProp} from "@mui/material/Typography";

export interface ListItemLinkClasses extends ListItemClasses {
    icon: string,
    text: string,
    active: string,
    activeText: string
}

export interface ListItemLinkProps extends  ListItemBaseProps {
    to: string,
    active: boolean,
    icon?: React.ReactNode,
    activeIcon?: React.ReactNode,
    showText: boolean,
    primary: React.ReactNode,
    color?: ColorProp;
    target?: '_blank'|''
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {

    },
    active: {
        paddingLeft: theme.spacing(3),
        ['& .MuiSvgIcon-root']: {
            color: theme.palette.primary.main
        }
    },
    activeText: {
        fontWeight: 'bold',
    },
    icon: {
        minWidth: 24
    },
    text: {
        transform: 'translateY(2px)',
    }
}))

const ListItemLink: React.FC<ListItemLinkProps> = ({ icon, activeIcon, showText, primary, to, target, active=false, color }) => {
    const renderLink = React.useMemo(
        () =>
          React.forwardRef(function Link( itemProps, ref:  React.Ref<HTMLAnchorElement>) {
                return <RouterLink to={to} target={target} ref={ref} {...itemProps} role={undefined} />;
            }),
        [to],
    );
    const classes = useStyles();

    const listItemClasses = [classes.root]
    const textClasses = [classes.text]
    const listItemActiveIcon = activeIcon? <ListItemIcon className={classes.icon}>{activeIcon}</ListItemIcon>: null
    const defaultIcon = icon? <ListItemIcon className={classes.icon}>{icon}</ListItemIcon> : null

    if (active) {
        //listItemClasses.push(classes.active)
        textClasses.push(classes.activeText)
    }

    return <ListItem component={renderLink}
                     sx={{ mt : 0.5}}
                     className={clsx(...listItemClasses)}>
        {active? listItemActiveIcon: defaultIcon}
        {showText && <ListItemText
            sx={{ ml: 2}}
            primaryTypographyProps={{
                className: clsx(...textClasses),
                color: color
        }} primary={primary} />}
    </ListItem>;
}
export default ListItemLink
