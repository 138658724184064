import {Alert, AlertTitle, Snackbar, SnackbarOrigin} from '@mui/material'
import axios, {AxiosError} from 'axios'
import React, {createContext, ReactElement, useContext, useState} from 'react'

export interface SnackbarOptions {
  autoHideDuration?: number
  anchorOrigin?: SnackbarOrigin
}

export interface Storage {
  get(key: string): Promise<string|null>
  remove(key: string): Promise<void>
  set(key: string, value: any): Promise<boolean>

  getObject<T extends object>(key: string): Promise<T|null>
  setObject<T extends object>(key: string, value: T): Promise<boolean>
}

const DEFAULT_PREFIX="bytenite"

export interface StorageProviderProps extends React.PropsWithChildren {
}

const defaultContext: Storage = {
  get: () => Promise.reject('Storage not loaded'),
  remove: () => Promise.reject('Storage not loaded'),
  getObject: () => Promise.reject('Storage not loaded'),
  set: () => Promise.reject('Storage not loaded'),
  setObject: () => Promise.reject('Storage not loaded'),
}

const Context = createContext<Storage>({...defaultContext})

export const StorageProvider: React.FC<StorageProviderProps> = ({children}) => {

  //const localStorage = new LocalStora


  const getValue = async (key: string) => {
    return localStorage.getItem(`${DEFAULT_PREFIX}-${key}`)
  }
  const setValue = async (key: string, value: any) => {
    localStorage.setItem(`${DEFAULT_PREFIX}-${key}`, value)
    return true
  }
  const remove = async (key: string) => {
    localStorage.removeItem(`${DEFAULT_PREFIX}-${key}`)
  }

  const localStorageProvider: Storage = {
    get: getValue,
    set: setValue,
    remove: remove,
    getObject: async <T,>(key: string) => {
      const strVal = await getValue(key)
      if (!strVal) {
        return null
      }
      return JSON.parse(strVal) as T
    },
    setObject: async <T,>(key: string, value: T|null) => {
      if (!value) {
        remove(key)
        return true
      }
      const strVal = JSON.stringify(value)
      if (!strVal) {
        return false
      }
      setValue(key, strVal)
      return true
    }
  }
  //
  return (
    <>
      <Context.Provider value={localStorageProvider}>{children}</Context.Provider>
    </>
  )
}

export const useStorage = () => useContext(Context)

export default StorageProvider
