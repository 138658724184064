import {
    Alert,
    Box,
    BoxProps,
    Button,
    TextField,
    Typography
} from '@mui/material';

import {ReactComponent as ArrowRightWhite} from "../../assets/jobIcons/arrow-right-white.svg"
import {ReactComponent as ArrowRightGray} from "../../assets/jobIcons/arrow-right-gray.svg"
import React, {useState} from 'react';
import {LocalOffer} from "@mui/icons-material";

export interface RedeemCouponResponse {
    id?: string;
    code?: string;
    amount?: number;
}

export type ExchangeRate = {
    exchangeRate?: string;
    amount?: string;
    currency?: string;
    currencyAmount?: string;
}

export type PromoCodeFormProps = {
    box?: BoxProps,
    balance?: number,
    onPromoCodeRequest: (code: string) => Promise<RedeemCouponResponse>
}
interface Error {
    code: number
    message: string
}

export default function PromoCodeForm({box, onPromoCodeRequest, balance}: PromoCodeFormProps) {
    const boxProps = {...box}
    const [currentStep, setCurrentStep] = useState(0);
    const [code, setCode] = useState('');
    const [amount, setAmount] = useState(0);


    const [err, setErr] = useState<string|null>(null)

    const handlePromoCodeRedeem = () => {
        setErr('')
        onPromoCodeRequest(code).then(resp => {
            setAmount(resp.amount||0)
            setCurrentStep(1)
        }).catch(err => {
            if (err?.json) {
                err.json().then((errVal: Error) => {
                    if (errVal.code === 5) {
                        setErr('The code you entered is not valid')
                    } else {
                        setCurrentStep(-1)
                    }
                }).catch(() => {
                    setCurrentStep(-1)
                })
            }
        })
    }


    switch (currentStep) {
        case 0:
            return <Box {...boxProps} justifyContent="center" display="flex" flexDirection="column" alignItems="center">
                <Box textAlign="center">
                    <Typography variant="caption2" color="secondary.dark">Current balance</Typography>
                    <Typography variant="h1" color={code?.length < 2 ? 'textSecondary':'secondary.dark'}>{balance}</Typography>
                    <Typography variant="body2" color="secondary.dark">ByteChips</Typography>
                </Box>
                <Box mt={2} display="flex">
                    <TextField value={code} sx={{minWidth : "200px"}}
                               error={!!err}
                               fullWidth
                               onChange={(e) => {
                                   if (err) {
                                       setErr('')
                                   }
                                   setCode(e.target.value)
                               }}
                               InputProps={{ endAdornment : <LocalOffer/> }}
                               label="Code" helperText="Enter your promo code" variant="outlined" color="secondary" type="text"/>
                </Box>
                {err ? <Box my={2}>
                    <Alert color="error" title="Error">
                        {err}
                    </Alert>
                </Box>: null}
                <Box my={2}>
                    <Button color="primary" variant="contained" disabled={code?.length < 2 || !!err} onClick={handlePromoCodeRedeem}>
                        <Typography
                          mx={1} variant={"button1"}>Next
                        </Typography>
                        {(code?.length < 2 || !!err) ? <ArrowRightGray></ArrowRightGray> : <ArrowRightWhite></ArrowRightWhite>}
                    </Button>
                </Box>
            </Box>
        case 1:
            return (<Box className="TopUpForm" {...boxProps} justifyContent="center" display="flex" flexDirection="column" alignItems="center">
                <Box textAlign="center">
                    <Typography variant="caption">Promo code successfully redeemed</Typography>
                    <Typography variant="h1">{amount}</Typography>
                    <Typography variant="body2">ByteChips</Typography>
                </Box>
                <Box mt={2}>

                </Box>
            </Box>)
        default:
            return (<Box className="TopUpForm" {...boxProps} justifyContent="center" display="flex" flexDirection="column" alignItems="center">
                <Alert color="error">An error occurred</Alert>
            </Box>)
    }
}

