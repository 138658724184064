import * as React from "react";
import {Chip, Typography} from "@mui/material";
import {CloudUpload} from "@mui/icons-material";

const ProgressChip = () => {

    return (
        <Chip label={
            <>
                <Typography><CloudUpload fontSize="small"/> Uploading</Typography>
            </>
        }
              variant="outlined" color={"warning"}
        />
    );
}

export default ProgressChip
