import React from 'react';
import Grid from "@mui/material/Grid";
import { CircularProgress, Divider, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DisplayDataTable from "./displayDataComponents/_displayDataTable";
import { HttpDataSource, JobDataSource, StorjDataSource } from "../types/JobDataSource";
import { JobParams, Output } from "../types/JobParams";
import { ReactComponent as BytechipBlueIcon } from "../assets/walletIcons/chip-estimation.svg";
import { JobPreset } from "./formComponents/JobPresetForm";

export interface ReviewCreateJobProps {
    optionsPerformance: any
    input: JobDataSource;
    template: any;
    outputs: any;
    jobCostEstimation: number;
    availableBalance: number;
    errorEstimation: boolean;
    fileIsUploading: boolean;
    presets: JobPreset[]
}

const formatInput = (input: JobDataSource) => {
    const columnsInput = ["Source", "Destination"];
    const dataInput = [];
    const inputRecord = { "Source": "", "Destination": "" }
    if (input.source && input.source.dataSourceDescriptor) {
        let params: StorjDataSource
        switch (input.source.dataSourceDescriptor) {
            case "url":
                inputRecord["Source"] = (input.source.params as HttpDataSource).url
                break
            case "storj":
                params = input.source.params as StorjDataSource
                inputRecord["Source"] = `Storj: ${params.bucket_name}/${params.name}`
                break
            default:
                inputRecord["Source"] = input.source.params.name

        }
    }
    if (input.destination && input.destination.dataSourceDescriptor) {
        let params: StorjDataSource
        switch (input.destination.dataSourceDescriptor) {
            case "url":
                inputRecord["Destination"] = (input.destination.params as HttpDataSource).url
                break
            case "bucket":
                inputRecord["Destination"] = "Temporary bucket"
                break
            case "storj":
                params = input.source.params as StorjDataSource
                inputRecord["Destination"] = `Storj: ${params.bucket_name}/${params.name}`
                break
            default:
                inputRecord["Destination"] = input.destination.params.name

        }
    }
    dataInput.push(inputRecord)
    return { columnsInput, dataInput }
}

const formatTemplate = (template: string, selectedPreset?: JobPreset) => {
    if (selectedPreset) {
        return {
            dataTemplate: [{ "Muxing": selectedPreset.category, "Template": selectedPreset.name }],
            columnsTemplate: ["Muxing", "Template"]
        }
    }
    return {
        dataTemplate: [{ "Muxing": template.trim(), "Template": "none" }],
        columnsTemplate: ["Muxing", "Template"]
    }
}

const formatParams = (params: JobParams) => {
    const dataParams: any = [];
    const columnsParams = ["Output", "Format", "Aspect", "Video", "Audio", "Filters"];

    params.outputs.forEach((output: Output, index) => {

        const aspectRatio = output.output_params.aspect.resolution.aspect_ratio;
        const rotation = output.output_params.aspect.orientation.rotation;
        const height = output.output_params.aspect.resolution.height === 'custom' ? output.output_params.aspect.resolution.custom_height.toString() + 'p' : output.output_params.aspect.resolution.height
        const aspectString =
            (aspectRatio ? (aspectRatio + ', ') : '') +
            (rotation ? (rotation + ', ') : '') +
            height;

        const videoString =
            output.output_params.video.codec;

        const audioString =
            output.output_params.audio.audio_codec;

        dataParams.push({
            "Output": output.name || "Output" + (index + 1),
            "Format": output.output_type.toUpperCase(),
            "Aspect": aspectString,
            "Video": videoString,
            "Audio": audioString,
            "Filters": ""
        })
    })

    return { dataParams, columnsParams }
}



const ReviewCreateJob = ({ ...props }: ReviewCreateJobProps) => {
    const { input, template, outputs, jobCostEstimation, availableBalance, errorEstimation, fileIsUploading, presets = [] } = props

    const selectedPreset = presets.find(p => p.id === template)

    return (
        <Grid container spacing={2} my={3} >
            <Grid item md={12}  >
                <div className={"container-horizontal content-center"}>
                    {/*<Box mx={5}>
                        <RadioFormControl
                            variantFormLabel={"h3"}
                            // @ts-ignore
                            variantControlLabel={"caption1"}
                            colorControlLabel={"secondary.dark"}
                            colorFormLabel={"secondary.dark"}
                            //TODO: onchange
                            row={false}
                            required={false}
                            label={"Performance level"}
                            id={"performance-level"}
                            value={"balanced"}
                            labelMargin={4}
                            options={optionsPerformance}
                            className={"container-horizontal content-space-between"}
                        >
                        </RadioFormControl>
                    </Box>
                    <Box mx={5}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography
                                            // @ts-ignore
                                            variant={"caption1"} color={"secondary.dark"}>Off</Typography>
                                        <Switch color={"primary"} defaultChecked />
                                        <Typography
                                            // @ts-ignore
                                            variant={"caption1"} color={"secondary.dark"}>On</Typography>
                                    </Stack>
                                }
                                label={
                                    <Typography mx={2} variant={"h3"} color={"secondary.dark"}>
                                        Trusted processing
                                    </Typography>
                                }
                                labelPlacement={"start"}
                            />
                        </FormGroup>
                    </Box>*/}
                </div>

            </Grid>
            {/*<Grid item md={12} className={"container-horizontal content-space-between"} >
                <Divider className={"divider-blue-5"}></Divider>
            </Grid>*/}
            <Grid item md={12} >
                {/* <div className={"container-horizontal content-center"}>
                    <Box m={2} display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                        <Typography m={2} variant={"body1"} color={"grey.100"}>Estimated price</Typography>
                        <Paper elevation={3}>
                            {fileIsUploading ?
                                (<Box m={2}><CircularProgress></CircularProgress></Box>)
                                :  (availableBalance < jobCostEstimation ?
                                <Typography m={1.5} variant={"h3"} color={"error"}>
                                    {jobCostEstimation} *
                                </Typography> :
                                (errorEstimation ?
                                        <Typography m={1.5} variant={"h3"} color={"error"}>
                                            {0} *
                                        </Typography> :
                                <Typography m={1.5} ml={2.5} variant={"h3"} color={"secondary.dark"}>
                                    {jobCostEstimation} <BytechipBlueIcon></BytechipBlueIcon>
                                </Typography>
                                )
                            )
                            }
                        </Paper>
                    </Box>
                    <Box m={2} className={"container-horizontal content-center"}>
                        <Typography m={2} variant={"body1"} color={"grey.100"}>Estimated time</Typography>
                        <Paper elevation={3}>
                            <Typography m={1.5} variant={"h3"} color={"secondary.dark"}>
                                01:03:27
                            </Typography>
                        </Paper>
                    </Box>
                </div> */}
                {availableBalance < jobCostEstimation || errorEstimation || fileIsUploading ?
                    <Box m={2} className={"container-horizontal content-center"}>
                        {
                            fileIsUploading ?
                                <Typography variant={"subtitle2"} color={"error"}>
                                    The video is still being uploaded in background.
                                    Please wait completion of upload before launching the job
                                </Typography> :
                                errorEstimation ?
                                    <Typography variant={"subtitle2"} color={"error"}>
                                        * The video input source you provided it's not supported
                                    </Typography> :
                                    <Typography variant={"subtitle2"} color={"error"}>
                                        * You need to top up credits to be able to run this job
                                    </Typography>
                        }
                    </Box> : <></>
                }
            </Grid>
            <Grid item md={12} className={"container-horizontal content-space-between"} >
                <Divider className={"divider-blue-5"}></Divider>
            </Grid>
            <Grid item md={12} className={"container-vertical"} >
                <Typography ml={3} variant={"h3"} color={"secondary.dark"}>
                    Input
                </Typography>
                <DisplayDataTable
                    data={formatInput(input).dataInput}
                    columnsNames={formatInput(input).columnsInput}
                    tableCellNoBorder={true}
                    columnsOrder={formatInput(input).columnsInput} />
            </Grid>
            <Grid item md={12} className={"container-horizontal content-space-between"} >
                <Divider className={"divider-blue-5"}></Divider>
            </Grid>
            <Grid item md={12} className={"container-vertical"} >
                <Typography ml={3} variant={"h3"} color={"secondary.dark"}>
                    Template
                </Typography>
                <DisplayDataTable
                    data={formatTemplate(template, selectedPreset).dataTemplate}
                    columnsNames={formatTemplate(template, selectedPreset).columnsTemplate}
                    tableCellNoBorder={true}
                    columnsOrder={formatTemplate(template, selectedPreset).columnsTemplate}
                />
            </Grid>
            <Grid item md={12} className={"container-horizontal content-space-between"} >
                <Divider className={"divider-blue-5"}></Divider>
            </Grid>
            <Grid item md={12} className={"container-vertical"} >
                <Typography ml={3} variant={"h3"} color={"secondary.dark"}>
                    Output
                </Typography >
                <DisplayDataTable
                    data={formatParams(outputs).dataParams}
                    columnsNames={formatParams(outputs).columnsParams}
                    tableCellNoBorder={false}
                    columnsOrder={formatParams(outputs).columnsParams}
                />
            </Grid>
        </Grid>
    );
}

export default ReviewCreateJob;
