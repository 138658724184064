import {Alert, alpha, Box, BoxProps, Collapse, TextField, Typography} from "@mui/material";
import React from "react";
import {ValidatorComponent, ValidatorComponentProps, ValidatorForm} from "react-material-ui-form-validator";
import {Error, Folder, FolderOpen, RadioButtonChecked, RadioButtonUnchecked} from "@mui/icons-material";
import FileExplorer, {Directory} from "../FileExplorer";


export interface DropboxOutputFormProps extends BoxProps {
    rootDirectory?: Directory;
    onChange?: (formData: any) => any;
    onCancel?: (isUploading: boolean) => any;
    disabled?: boolean;
    allowedExtensions?: string[]
    value: {
        name?: string
    }
}


ValidatorForm.addValidationRule('dropbox_output', (value) => {
    return value?.name;
});

export class DropboxOutputForm extends ValidatorComponent {
    props: ValidatorComponentProps & DropboxOutputFormProps
    //state: V

    constructor(props: ValidatorComponentProps & DropboxOutputFormProps) {
        super(props);
        this.props = props;
    }

    renderValidatorComponent() {
        const {value, errorMessages, validatorListener, onChange, rootDirectory, allowedExtensions, disabled=false, ...rest} = this.props;

        const handleDirectoryChange = (selectedDir: Directory) => {
            const updatedValue = {...value, path: selectedDir.path}
            if (onChange) {
                onChange(updatedValue)
            }
        }
        const handleFileNameChange = (newName: string)  => {
            const updatedValue = {...value, name: newName}
            if (onChange) {
                onChange(updatedValue)
            }
        }

        return (
            <>
                <TextField name="name" label="File name" sx={{mb:2, mt:0}} value={value?.name||''} required onChange={(e) => handleFileNameChange(e.target.value) } error={!this.isValid()} helperText={this.isValid() ? '' : 'File name is required'} fullWidth/>
                <Box {...rest} sx={{minHeight: 160, flexGrow: 1, maxHeight: 480, overflowY: 'auto', overflowX: 'hidden'}} flexDirection="column" display="flex" alignItems="start">
                    <FileExplorer rootDirectory={rootDirectory} selectedPath={value?.path} onChange={(f) => handleDirectoryChange(f) } allowedExtensions={allowedExtensions} disabled={disabled} selectDirectory/>
                </Box>
            </>
        );
    }

    onFileChange(e: any) {
        const file = e && e.target.files && e.target.files.length > 0 ? e.target.files[0] : null
        if (this.props.onChange) {
            this.props.onChange(file)
        }
    }

    onCancel(isUploading: boolean) {
        if (this.props.onCancel) {
            this.props.onCancel(isUploading)
        }
    }

    getErrorMessage() {
        return "this field is required"
    }

    errorText() {
        const isValid = this.isValid()
        if (isValid) {
            return null;
        }

        return (
            <Box mt={2}>
                <Typography variant="subtitle2" color="error"><Error color="error" fontSize="small" sx={{transform: 'translateY(2px)'}}/> {this.getErrorMessage()}</Typography>
            </Box>
        );
    }
}


export default DropboxOutputForm
