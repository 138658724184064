import {
    Box,
    BoxProps,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    CardProps,
    Grid, IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import LinkIcon from '@mui/icons-material/Link';

import React, {useState} from 'react';
import {Cancel, CheckCircle, KeyOff, Label, SvgIconComponent} from "@mui/icons-material";
import SelectableCard from "@bytenite/components/src/components/SelectableCard";
import FormattedDate from "@bytenite/components/src/components/FormattedDate";
import ListItemLink from 'components/src/components/itemComponents/ListItemLink'

interface DataRow {
    id: string;
    name: string;
    fingerprint: string;
    issuedAt: string;
    expiresAt: string;
    revoked: boolean;
}


export type ApiKeysTableProps = {
    box?: BoxProps
    data: DataRow[]
    onApiKeyRevoke: (id: string) => Promise<any>
}


export default function ApiKeysTable({box, data, onApiKeyRevoke, ...props}: ApiKeysTableProps) {
    const boxProps = {...box}
    const [selected, setSelected] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const revokeApiKey = (id: string) => {
        setIsLoading(true)
        onApiKeyRevoke(id).finally(() => {
            setIsLoading(false)
        })
    }
    return (
        <Box className="DataSource" {...boxProps}>
            <TableContainer>
                <Table aria-label="Ape keys" style={{ width: '100%' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Id</TableCell>
                            <TableCell>Issued At</TableCell>
                            <TableCell>Expires At</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row: DataRow) => (
                          <TableRow key={row.id}>
                              <TableCell component="th" scope="row">{row.name}</TableCell>
                              <TableCell> {row.id}</TableCell>
                              <TableCell><FormattedDate dateString={row.issuedAt}/></TableCell>
                              <TableCell><FormattedDate dateString={row.expiresAt}/></TableCell>
                              <TableCell sx={{minWidth: '150px'}}>
                                  {row.revoked?
                                    <Box><Typography mx={1} variant={"subtitle1"} color="error">Revoked</Typography></Box> :
                                    <Button variant="text" size="small" color="error" disabled={isLoading} onClick={() =>revokeApiKey(row.id)}><KeyOff fontSize="small"/> <Typography mx={1} variant={"subtitle2"}>revoke</Typography></Button>}
                              </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

